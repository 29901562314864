import { Component, OnInit,EventEmitter, Output, Input } from '@angular/core';
import {User} from '../../../models/user';

@Component({
  selector: 'app-pagos-micro-seguros-alta',
  templateUrl: './pagos-micro-seguros-alta.component.html',
  styles: []
})
export class PagosMicroSegurosAltaComponent implements OnInit {

  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public periodoArray: Array<string> = new Array<string>();
  public selectedPeriodoPago:string ='';
  public fechaPago: string= new Date().toISOString().split('T')[0];
  public aaaa:string = ((new Date()).getFullYear()).toString();
  public mm:string= ((new Date()).getMonth()+1).toString();
  public currentPeriodo:string;
 
  public importePago:string='50';

  constructor() { }

  ngOnInit() {
    var am: string;
    if(this.mm.length==1){this.mm='0'+ this.mm;}
    this.currentPeriodo=this.aaaa+'-'+this.mm;
    for (var a = 2020; a <= 2050; a++) {
      for (var m = 1; m <= 12; m++) {
        if (m.toString().length==1){am='0'+m.toString();}
        else {am=m.toString();}
        this.periodoArray.push(a.toString()+'-'+am);
      }
    }
    this.selectedPeriodoPago=this.currentPeriodo;
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onPay(){
    
  }

}
