import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import  *  as  jsSMEnabledButtons from  '../../../../assets/catalogo/jsStateMachineEnabledButtonsWorkflow.json';


@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html'
})
export class PagosComponent implements OnInit {

  
  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  btnCancelar:boolean=false;
  btnRecupero:boolean=false;

  confSMEnabledButtons: any = (jsSMEnabledButtons as any).default;

  configButtons: any = {
    id: '',
    form: '',
    boton: '',
    tipoProductos: '',
    estados: '',
    roles: ''
  };

  public selectedSolicitud: any={ idSolicitud: '',
                                idLineaProducto: '',
                                descLineaProducto: '',
                                idProducto: '',
                                descProducto: '',
                                idTipoProducto: '',
                                descTipoProducto: '',
                                idEstado: '',
                                descEstado: '',
                                abrevEstado: '',
                                nroTributarioCliente: '',
                                descCliente: '',
                                fechaAlta: '',
                                fechaBaja: '',
                                descUsuario: ''};

  urlAPI: string='https://4ll0n8p6sl.execute-api.us-east-2.amazonaws.com/beta/dataproductos';
  urlAPIPagos: string='https://wbbacm21t4.execute-api.us-east-2.amazonaws.com/beta/pagos';
  urlAPICuenta: string='https://wbbacm21t4.execute-api.us-east-2.amazonaws.com/beta/cuenta';
  urlAPIPunitorios: string='https://wbbacm21t4.execute-api.us-east-2.amazonaws.com/beta/punitorios';
  

  

  public cuotas:any;
  public onShowForm: string;
  public stateBtnMp: boolean=false;
  public pagos = [];
  public punitorios = [];
  public cuentas = [];
  

  public operacion: any={
      idSolicitudPrestamo: '',
      nroOperacionTransf:''
  }


  public selectedCuota: any={
    amortizacion: '',
    capitalAdeudado: '',
    descEstado: '',
    fechaPago: '',
    fechaVencimiento: '',
    gastos: '',
    idProdPrestamoCuotasLiq: '',
    interes: '',
    nroCuota: '',
    pagable: '',
    pagada: '',
    valorCuota: '',
    adelantable: '',
    punitorios : '',
    saldoAnterior: '',
    ultimaCuota:'',
    newPunitorios:'',
    idNewEstado:'',
    descNewEstado:'',
    totalAPagar:'',
    mtoTotalPagoRecibido:'',
    saldoActual:'',
    idCanalPago:'',
    nroOperacionTransf:'' 
  }

  public liquidacion: any = { idProdPrestamoLiq: '',
                              idSolicitudPrestamo: '',
                              tasaLiq: '',
                              cuotasLiq: '',
                              montoLiq: '',
                              costoSaldoDeudor: '',
                              costoAmparo: '',
                              prima: '',
                              gastoAdministrativo: '',
                              tasaSuperIntendencia: '',
                              subTotalSeguros: '',
                              administracionFundacion: '',
                              totalSeguroCuota: '',
                              totalSegurosLiquidacion: '',
                              socioAdherente: '',
                              retencionDesembolso: '',
                              totalDesembolso: '',
                              tem: '',
                              valorCuotaLiq: '',
                              fechaDesembolso: '',
                              idCanalDesembolso: '',
                              descCanalDesembolso: '',
                              cbu: '',
                              nroOperacionTransf: '',
                              idEstado: '',
                              adeudado: '',
                              punitorios: '',
                              atrasos: '',
                              cancelaciones: '',
                              cuotasLiquidadas: '',
                              idSolicitudSegMed: '',
                              idSolicitud: '',
                              fechaIniVigencia: '',
                              fechaFinVigencia: '',
                              vigente: '',
                              descEstado: '',
                              idPersonaFyJTitular: '',
                              nroTributarioTitular: '',
                              descPersonaFyJTitular: '',
                              idTipoGrupoFam: '',
                              descTipoGrupoFam: ''};

  constructor(private getDataApiService:GetDataAPIService,@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.onSetFormShow('formProducto');
    this.stateBtnMp=false;
    this.btnCancelar=false;
    this.btnRecupero=false;
  }

  onSetFormShow(iForm:string){
    this.onShowForm=iForm;
  }

  setState(iState:String):String{
    var respuesta: String;
    if(iState=='N'){respuesta='label label-danger pull-right';}
    if(iState=='S'){respuesta='label label-success pull-right';}
    return respuesta;
  }

  setStateCuota(iState:String):String{
    var respuesta: String;
    if(iState=='TIEMPO Y FORMA'){respuesta='label label-success pull-right';}
    if(iState=='ADEUDADA'){respuesta='label label-warning pull-right';}
    if(iState=='ATRASADA'){respuesta='label label-danger pull-right';}
    if(iState=='ANTICIPADA'){respuesta='label label-success pull-right';}
    if(iState=='REFINANCIADA'){respuesta='label label-info pull-right';}
    if(iState=='REPROGRAMADA'){respuesta='label label-primary pull-right';}
    if(iState=='FONDO GARANTIA'){respuesta='label label-default pull-right';}
    if(iState=='FUERA TERMINO'){respuesta='label label-warning pull-right';}
    return respuesta;
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onGetData(){
        var respuesta: any;
        this.selectedSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
        this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI +'/solicitud',this.selectedSolicitud)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta=JSON.parse(data);
          if (respuesta['statusCode']=='200'){   
            this.liquidacion=respuesta['body'];
            this.stateBtnMp=true;
            this.onSetEditButtons();
            this.onGetDataPagos();
          }
          if (respuesta['statusCode']=='400'){   
            alert('ERROR - No se puede obtener los datos del producto');
          }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    
  }

  onGetDataPagos(){
    var respuesta: any;
    this.selectedSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPagos,this.selectedSolicitud)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.pagos=respuesta['body'];
        console.log('paso pago');
        this.onGetDataPunitorios();
      }
      if (respuesta['statusCode']=='400'){   
        alert('ERROR - No se puede obtener los datos de pagos');
      }
    })
    .catch((e: any) => {
      // error in e.message
      //alert('Error: ' + e.message);
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onGetDataPunitorios(){
    var respuesta: any;
    this.selectedSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPunitorios,this.liquidacion)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.punitorios=respuesta['body'];
        console.log('paso punit');
        this.onGetDataCuenta();
      }
      if (respuesta['statusCode']=='400'){   
        alert('ERROR - No se puede obtener los datos de punitorios');
      }
    })
    .catch((e: any) => {
      // error in e.message
      //alert('Error: ' + e.message);
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }


  onGetDataCuenta(){
    var respuesta: any;
    this.selectedSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPICuenta,this.selectedSolicitud)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.cuentas=respuesta['body'];
        console.log('paso cuenta');
      }
      if (respuesta['statusCode']=='400'){   
        alert('ERROR - No se puede obtener los datos de cuentas');
      }
    })
    .catch((e: any) => {
      // error in e.message
      //alert('Error: ' + e.message);
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }
  
  onExit(){
    this.closeEvent.emit(true);
  }

  setStateProducto(iState:String):String{
    var respuesta: String;
    if(iState=='VIGENTE'){respuesta='label label-success pull-left';}
    if(iState=='BAJA'){respuesta='label label-info pull-left';}
    if(iState=='CANCELADA'){respuesta='label label-warning pull-left';}
    if(iState=='MORA'){respuesta='label label-danger pull-left';}
    if(iState=='INCOBRABLE'){respuesta='label label-danger pull-left';}
    if(iState=='RECUPERO'){respuesta='label label-warning pull-left';}
    return respuesta;
  }

  listenAssignEventCliente(iSolicitud: any){
    this.selectedSolicitud=iSolicitud;
    this.onGetData();
  }
  
  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='formProducto';
  }

  listenCloseEventPagosCuotas(iMenuItemSelected: any){
    this.onShowForm='formProducto';
    this.onGetData();
  }

  onPagar(iNroCuota:String){
    this.selectedCuota=this.liquidacion.cuotasLiquidadas.find(x => x.nroCuota == iNroCuota);
    this.onShowForm='formPagoCuota';
  }

  setBtnPagoMP(iState:String):String{
    var respuesta: String;
    if(iState=='N') {respuesta='btn btn-default btn-block disabled';}
    if(iState=='S') {respuesta='btn btn-default btn-block';}
    return respuesta;
  }

  setBtnPagoMS(iState:boolean):String{
    var respuesta: String;
    if(iState){respuesta='btn btn-default btn-block';}
    else {respuesta='btn btn-default btn-block disabled';}
    return respuesta;
  }


  onSetEditButtons() {

    this.configButtons=this.confSMEnabledButtons.find(element => element.id===9);
    this.btnCancelar=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!==-1) && (this.configButtons.tipoProductos.indexOf(this.selectedSolicitud.idTipoProducto)!==-1) && (this.configButtons.estados.indexOf(this.selectedSolicitud.idEstado)!== -1);
    

    this.configButtons=this.confSMEnabledButtons.find(element => element.id===10);
    this.btnRecupero=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!==-1) && (this.configButtons.tipoProductos.indexOf(this.selectedSolicitud.idTipoProducto)!==-1) && (this.configButtons.estados.indexOf(this.selectedSolicitud.idEstado)!== -1);
  
  
  }

}
