import { Component, OnInit,EventEmitter, Output, Input,Inject} from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';



@Component({
  selector: 'app-person-search',
  templateUrl: './person-search.component.html'
})
export class PersonSearchComponent implements OnInit {


  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  public criterio$: string='';
  public selectedPerson: any={idPersonasFyJ:'',
                              nroTributario:'',
                              descPersonasFyJ:'',
                              descTipoPersona:'',
                              promotor:'',
                              certificado:'',
                              bcra:''};
  public promotor: any={nickNamePromotorAlta:''};

  public onShowForm: string='';
  public btnVisible: boolean=false;

  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() owner: string;

  urlAPI: string='https://hdi2ww4pg6.execute-api.us-east-2.amazonaws.com/beta/datapersonaasfyj';
  urlAPIPFyJ: string='https://wztuwme9u9.execute-api.us-east-2.amazonaws.com/beta/datapersonasfisicas/bcraafip';
 
  public personas = [];
  public personasFiltro = [];
  

  ngOnInit() {
    this.selectedPerson={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',promotor:'',certificado:''};
    this.criterio$='';
    this.personas = [];
    this.personasFiltro = [];
    this.onShowForm='formPersonaFyJ';
    if (this.owner=='CARTERA'){
      this.btnVisible=true;
      if(this.iUserVO$.descRol.toUpperCase()=='PROMOTOR'){
        this.getDataPromo();
      }
      if((this.iUserVO$.descRol.toUpperCase()=='ADMINISTRADOR') || (this.iUserVO$.descRol.toUpperCase()=='SUPERVISOR')){
        this.getDataAdmin();
      }
      
    }
    if (this.owner=='GARANTE'){
      this.btnVisible=false;
      this.getDataAdmin();
    }
     
    
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  getDataAdmin(){
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if ((respuesta['statusCode']=='200') && (respuesta['body']!=[])){   
        this.personas=respuesta['body'];
        this.personasFiltro=this.personas;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataPromo(){
    var respuesta: any;
    this.promotor.nickNamePromotorAlta=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/promotor',this.promotor)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if ((respuesta['statusCode']=='200') && (respuesta['body']!=[])){   
        this.personas=respuesta['body'];
        this.personasFiltro=this.personas;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }


  onSearchCriterio(){
    this.selectedPerson={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:''};
    this.personasFiltro =[];
    this.personasFiltro=this.personas.filter(element => element.descPersonasFyJ.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || String(element.nroTributario).indexOf(this.criterio$,0)>-1 || element.promotor.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1);
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iPerson:any){
    this.selectedPerson=iPerson;
    this.onSearchNroTributario();
  }

  onAssign(){
    if ((this.selectedPerson.nroTributario!='') && (this.selectedPerson.descTipoPersona=='FISICA') && (this.selectedPerson.bcra=='OK BCRA')) {
      this.assignEvent.emit(this.selectedPerson);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar una persona Física sin problemas en la Central de Deudores del BCRA');
    } 
  }

  onNewF(){
    this.onShowForm='formNuevaPersonaFisica';
  }

  onNewJ(){
    this.onShowForm='formNuevaPersonaJuridica';
  }

  onEdit(){
    if (this.selectedPerson.nroTributario!='') {
      if (this.selectedPerson.descTipoPersona=='FISICA') {
        this.onShowForm='formEditPersonaFisica';
      } 
      else {
        this.onShowForm='formEditPersonaJuridica';
      }
    } 
    else{
      alert('Debe seleccionar una persona');
    } 
  }
    
  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='formPersonaFyJ';
    this.ngOnInit();
  }

  onSearchNroTributario(){
    var respuesta: any; var itemPromotor: any, qryNroTributario: any;
    qryNroTributario={nroTributario:''};
    qryNroTributario.nroTributario=this.selectedPerson.nroTributario;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPFyJ,qryNroTributario)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.selectedPerson.bcra=respuesta['body']['bcra'];
      }
      else{
        alert('Persona no encontrada');
        this.onExit();
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  setBCRA(iState:String):String{
    var respuesta: String;
    if(iState=='OK BCRA'){respuesta='label label-success pull-rigth';}
    if(iState=='MAL BCRA'){respuesta='label label-danger pull-rigth';}
    return respuesta;
  }

}
