import { Component, OnInit, Input } from '@angular/core';
import {User} from '../../../models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() iUserVO$: User;
  urlLogout$: string= '/';
  rolSettings$: boolean = false;
  uriLogo:string='';
  uriUser:string='';
  
  constructor() { }

  ngOnInit() {
    this.uriLogo='assets/logos/' + this.iUserVO$.idTenant + '.jpg';
    this.uriUser='assets/imguser/' + this.iUserVO$.idTenant + '/' + this.iUserVO$.picture;
  }

}
