import { Component, OnInit,EventEmitter, Output} from '@angular/core';
import  *  as  jsActividadAfip  from  '../../../../assets/catalogo/jsActividadAfip.json';


@Component({
  selector: 'app-afip-actividad',
  templateUrl: './afip-actividad.component.html'
})
export class AfipActividadComponent implements OnInit {

  public criterio$: string='';
  public selectedActividad: any={idActividadEconAfip : '', codActividadF883 : '',descActividadF883 : '',desclActividadF883 : ''};
  public actividades: any = (jsActividadAfip as any).default;
  public actividadesFiltro = [];
  
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
    this.onSearchCriterio();
  }

  onSearchCriterio(){
    this.selectedActividad={idActividadEconAfip : '', codActividadF883 : '',descActividadF883 : '',desclActividadF883 : ''};
    this.actividadesFiltro =[];
    this.actividadesFiltro=this.actividades.filter(element => element.descActividadF883.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || String(element.codActividadF883).indexOf(this.criterio$,0)>-1);
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iActividad:any){
    this.selectedActividad=iActividad;
  }

  onAssign(){
    if (this.selectedActividad.codActividadF883!='') {
      this.assignEvent.emit(this.selectedActividad);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar una actividad');
    } 
  }

}
