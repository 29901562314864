import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import { of } from 'rxjs';

@Component({
  selector: 'app-prod-clie-search',
  templateUrl: './prod-clie-search.component.html'
})
export class ProdClieSearchComponent implements OnInit {

  count$=of(NaN);

  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() owner: string;

  public criterio$: string='';
  public promotor: any={nickNamePromotorAlta:''};

  public cleanProductos: any={  idSolicitud: '',
                                idLineaProducto: '',
                                descLineaProducto: '',
                                idProducto: '',
                                descProducto: '',
                                idTipoProducto: '',
                                descTipoProducto: '',
                                idEstado: '',
                                descEstado: '',
                                abrevEstado: '',
                                nroTributarioCliente: '',
                                descCliente: '',
                                fechaAlta: '',
                                fechaBaja: '',
                                descUsuario: ''};
                                

  public selectedProductos:any = this.cleanProductos;
  
  public urlAPI: string='https://4ll0n8p6sl.execute-api.us-east-2.amazonaws.com/beta/dataproductos';                             
  public onShowForm: string=''; 
  public productos = [];
  public productosFiltro = []; 

  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    this.onShowForm='formProductoCliente';
    this.selectedProductos=this.cleanProductos;
    this.criterio$='';
    this.productos = [];
    this.productosFiltro = [];
    if(this.iUserVO$.descRol=='Promotor'){
      this.getDataPromo();
    }
    if(this.iUserVO$.descRol=='Administrador'){
      this.getDataAdmin();
    } 
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  getDataAdmin(){
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.productos=respuesta['body'];
        this.productosFiltro=this.productos;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataPromo(){
    var respuesta: any;
    this.promotor.nickNamePromotorAlta=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/usuario',this.promotor)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.productos=respuesta['body'];
        this.productosFiltro=this.productos;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onSearchCriterio(){
    this.selectedProductos=this.cleanProductos;
    this.productosFiltro =[];
    this.productosFiltro=this.productos.filter(element => element.abrevEstado.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.descCliente.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || String(element.nroTributarioCliente).indexOf(this.criterio$,0)>-1 || String(element.idSolicitud).indexOf(this.criterio$,0)>-1 || element.descLineaProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.descUsuario.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.fechaAlta.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1);
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iProducto:any){
    this.selectedProductos=iProducto;
  }

  onAssign(){
    if (this.selectedProductos.idSolicitud!='') {
      this.assignEvent.emit(this.selectedProductos);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar un Producto');
    } 
  }

  setState(iState:String):String{
    var respuesta: String;
    if(iState=='VIGENTE'){respuesta='label label-success pull-right';}
    if(iState=='BAJA'){respuesta='label label-info pull-right';}
    if(iState=='CANCELADA'){respuesta='label label-warning pull-right';}
    if(iState=='MORA'){respuesta='label label-danger pull-right';}
    if(iState=='INCOBRABLE'){respuesta='label label-danger pull-right';}
    if(iState=='RECUPERO'){respuesta='label label-warning pull-right';}
    return respuesta;
  }

  onDetalleProducto(){
    if (this.owner=='PAGOS'){
      if ((this.selectedProductos.idSolicitud!='') && ((this.selectedProductos.idEstado==2)||(this.selectedProductos.idEstado==10) )) {
        this.onShowForm='formDetalleProductoCliente';
      } 
      else{
        alert('Debe seleccionar un Producto');
      }
    }
    else{
      if (this.selectedProductos.idSolicitud!='') {
        this.onShowForm='formDetalleProductoCliente';
      } 
      else{
        alert('Debe seleccionar un Producto');
      }
    }
     
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.ngOnInit();
  }

  onRefresh(){
    this.ngOnInit();
  }

}
