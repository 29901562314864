import { Component, OnInit,Output,Input,EventEmitter, Inject} from '@angular/core';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import {User} from '../../../models/user';
import { DOCUMENT } from '@angular/common';
import {formatDate} from '@angular/common';
import  *  as  jsActividadAfip  from  '../../../../assets/catalogo/jsActividadAfip.json';
import  *  as  jsLocalidades  from  '../../../../assets/catalogo/jsLocalidades.json';
import  *  as  jsEstadosCiviles from  '../../../../assets/catalogo/jsEstadosCiviles.json';
import  *  as  jsNivelEducativo from  '../../../../assets/catalogo/jsNivelEducativo.json';
import  *  as  jsSocioEconomico from  '../../../../assets/catalogo/jsSocioEconomico.json';
import  *  as  jsTiposDocumentos from  '../../../../assets/catalogo/jsTiposDocumentos.json';
import  *  as  jsTiposPersonas from  '../../../../assets/catalogo/jsTiposPersonas.json';
import { globals } from 'src/app/globals';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-person-edit-f',
  templateUrl: './person-edit-f.component.html'
})
export class PersonEditFComponent implements OnInit {

  @Input() iUserVO$: User;
  @Input() idPersonasFyJ$: String;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  urlAPI: string='https://wztuwme9u9.execute-api.us-east-2.amazonaws.com/beta/datapersonasfisicas';
  urlAPIPromotores: string='https://akd7srf6f2.execute-api.us-east-2.amazonaws.com/beta/promotores';
  urlApiCertificar: string='https://d7n1f74ire.execute-api.us-east-2.amazonaws.com/beta/personas';
  
  onShowForm: string='';
  editPromotor: boolean;
  isChange:boolean=false;

  ltSexo: any=[{sexo:'M',descSexo:'Masculino'},{sexo:'F',descSexo:'Femenino'},{sexo:'O',descSexo:'Otro'}];
  ltLocalidades: any = (jsLocalidades as any).default;
  ltEstadosCiviles: any = (jsEstadosCiviles as any).default;
  ltNivelEducativo: any = (jsNivelEducativo as any).default;
  ltNivelSE: any = (jsSocioEconomico as any).default;
  ltTiposDocumentos: any = (jsTiposDocumentos as any).default;
  ltTiposPersonas: any = (jsTiposPersonas as any).default;
  ltActividadAfip: any = (jsActividadAfip as any).default;
  ltPromotores: any = [];
  ltCertificadores: any = [];

  newPersonaFisica: any= {  idPersonasFyJ: '',
                            nroTributario: '',
                            idActividadEconAfip: '',
                            email: '',
                            nroTelefono: '',
                            nroInterno: '',
                            nroCelular: '',
                            idLocalidad: '',
                            calle: '',
                            numero: '',
                            piso: '',
                            depto: '',
                            manzana: '',
                            casa: '',
                            barrio: '',
                            descPersonasFyJ: '',
                            Apellido: '',
                            Nombre: '',
                            fechaNacimiento: '',
                            sexo: '',
                            idNivelEducativo: '',
                            idEstadoCivil: '',
                            idNivelSE: '',
                            nroDocumento: '',
                            idTipoDocumento: '',
                            nickNamePromotorAlta:'',
                            nickNameUsuarioAlta:'',
                            certificado:'',
                            observacionDatosWeb:''};
                            
  selectedActividadEconomica: any={ idActividadEconAfip : '',
                                    codActividadF883 : '',
                                    descActividadF883 : '',
                                    desclActividadF883 : ''};

  selectedPerson: any ={idPersonasFyJ:''};

  certificacion: any ={ idPersonasFyJ:'',
                        fechaCertificacion:'',
                        nickNameCertificador:'',
                        observacion:''
                      };

  certificacionHistSelected: any ={idCertificacionPFyJ:'',
                        fechaCertificacion:'',
                        descUsuario:'',
                        observacion:''
                      };

  certificacionesHistoricas=[];

  public selectedPersonOut: any={idPersonasFyJ:'',
                              nroTributario:'',
                              descPersonasFyJ:'',
                              descTipoPersona:'',
                              promotor:'',
                              certificado:''};

  public oportunidades: any = {
    idSolicitud: '',
    idOportunidad: '',
    idSitioReferencia: '',
    idCliente: '',
    opCuotas: '',
    opMontoSolicitado: '',
    opNroTributario: '',
    opCP: '',
    idLocalidad: '',
    localidad: '',
    provincia : '',
    opEmail: '',
    opNroCelular: '',
    opDomicilio: '',
    idEstadoOportunidad: '',
    uuid: '',
    fechaRegistro: '',
    ideNroDoc: '',
    ideDenominacion: '',
    ideActividad: '',
    ideQSitUltMes: '',
    idemtoSit1UltMes: '',
    ideQSitMay1UltMes: '',
    ideMtoSitMay1UltMes: '',
    ideMaxSitUlt24Meses: '',
    ideMaxMontoUlt24Meses: '',
    ideQCheques: '',
    ideMtoCheques: '',
    ideImpGanancias: '',
    ideImpIVA: '',
    ideCategoria: '',
    ideIntegranteSoc: '',
    ideEmpleador: '',
    ideActividadMono: '',
    idCliOfertaMP: '',
    cftea: '',
    destino: '',
    grupoDestino: '',
    tna:''
  };



  constructor(private getDataApiService:GetDataAPIService,@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.selectedPerson.idPersonasFyJ=this.idPersonasFyJ$;
    this.editPromotor=(this.iUserVO$.descRol=='Promotor');
    this.getDataPromotores();
    this.getDataCertificaciones();
    this.getData();
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  getDataPromotores(){
    var respuesta: any;  
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPromotores)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.ltPromotores=respuesta['body']['visibles'];
        this.ltCertificadores=respuesta['body']['activos'];
        console.log(this.ltCertificadores);
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataCertificaciones(){
    var respuesta: any;  
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlApiCertificar+'/historico',this.selectedPerson)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.certificacionesHistoricas=respuesta['body'];
        console.log(this.certificacionesHistoricas);
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getData(){
    var respuesta: any;  var fecha: Date;
    fecha=new Date();
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/idpersonasfyj',this.selectedPerson)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.newPersonaFisica=respuesta['body'][0];
        this.selectedActividadEconomica=this.ltActividadAfip.filter(element => (element.idActividadEconAfip==respuesta['body'][0].idActividadEconAfip))[0];
        this.selectedPersonOut.idPersonasFyJ=this.newPersonaFisica.idPersonasFyJ;    
        this.selectedPersonOut.nroTributario=this.newPersonaFisica.nroTributario;
        this.selectedPersonOut.descPersonasFyJ=this.newPersonaFisica.descPersonasFyJ;
        this.selectedPersonOut.descTipoPersona='FISICA';
        this.selectedPersonOut.promotor=this.newPersonaFisica.nickNamePromotorAlta;
        this.certificacion.nickNameCertificador=this.newPersonaFisica.nickNamePromotorAlta;
        this.certificacion.fechaCertificacion=formatDate(fecha, 'yyyy-MM-dd', 'en');
        this.selectedPersonOut.certificado=this.newPersonaFisica.certificado;
        this.getDataOportunidades();
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataOportunidades(){
    var respuesta,getOportunidad: any;
    getOportunidad={};
    getOportunidad.idTenant=this.iUserVO$.idTenant;
    getOportunidad.nroTributario=this.newPersonaFisica.nroTributario;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/oportunidades',getOportunidad)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.oportunidades=respuesta['body'];
        this.isChange=false;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  yyyymmdd(i:string):string{
    var r:string;
    var dd: string, mm:string, yyyy:string;
    var z: any;
    z=i.split('-');
    yyyy=z[0]; mm=z[1]; dd=z[2];
    if(dd.length==1){dd='0'+dd;}
    if(mm.length==1){mm='0'+mm;}
    r=yyyy+'-'+mm+'-'+dd;
    return r;
  }
  

  onExit(){
    //console.log(JSON.stringify(this.newPersonaFisica));
    this.assignEvent.emit(this.selectedPersonOut);
    this.closeEvent.emit(true);
  }

  onSetFormShow(iForm: string){
    this.onShowForm=iForm;
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='';
  }

  listenAssignEvent(iActividadEconomica: any){
    this.selectedActividadEconomica=iActividadEconomica;
    this.newPersonaFisica.idActividadEconAfip=String(this.selectedActividadEconomica.idActividadEconAfip);

  }

  onSelect(iCertificacion:any){
    this.certificacionHistSelected=iCertificacion;
  }

  onCertificar(){
    var respuesta: any;
    this.getData();
    if (this.onCodigoError()=='111111111111'){
        this.certificacion.idPersonasFyJ=this.newPersonaFisica.idPersonasFyJ; 
        this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlApiCertificar,this.certificacion)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta=JSON.parse(data);
          if (respuesta['statusCode']=='200'){   
            this.certificacionesHistoricas=respuesta['body'];
            this.selectedPersonOut.certificado='CERTIFICADO';
            this.isChange=false;
            alert('Los datos de la persona se actualizaron exitosamente');
          }
          else{
            alert('No se puede certificar a la persona, revise los datos de certificación');
          }
        })
        .catch((e: any) => {
          // error in e.message
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    }
    else{
      alert(this.onLeyendaError(this.onCodigoError()));
    }
  }

  onAceptar(){
    if (this.onCodigoError()=='111111111111'){
      if (this.verificarCuil(String(this.newPersonaFisica.nroTributario))){
        var respuesta: any;
        this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/update',this.newPersonaFisica)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta=JSON.parse(data);
          if (respuesta['statusCode']=='200'){   
            this.selectedPersonOut.idPersonasFyJ=this.newPersonaFisica.idPersonasFyJ;    
            this.selectedPersonOut.nroTributario=this.newPersonaFisica.nroTributario;
            this.selectedPersonOut.descPersonasFyJ=this.newPersonaFisica.descPersonasFyJ;
            this.selectedPersonOut.descTipoPersona='FISICA';
            this.selectedPersonOut.promotor=this.newPersonaFisica.nickNamePromotorAlta;
            this.selectedPersonOut.certificado='NO CERTIFICADO';
            this.isChange=false;
            alert('Los datos de la persona se actualizaron exitosamente');
          }
          if ((respuesta['statusCode']=='400') && (respuesta['body']=='1062')){   
            alert('Verifique el Nro Tributario');
          }
          if ((respuesta['statusCode']=='400') && (respuesta['body']!='1062')){   
            alert('No se puede registrar a la persona, revise los datos OBLIGATORIOS');
          }
        })
        .catch((e: any) => {
          // error in e.message
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
      }
      else{
        alert('ERROR - Número Tributario inválido');
      }
    }
    else{
      alert(this.onLeyendaError(this.onCodigoError()));
    }
  }

  verificarCuil(cuil:String):Boolean{
    var verifica:Boolean=false;
    var control,x,xa,xb,xc,xd,xe,xf,xg,xh,xi,xj,dv: number;
    
    if (cuil.length == 11) {
      //Individualiza y multiplica los dígitos.
      xa = Number(cuil.substring(9,10)) * 2;
      xb = Number(cuil.substring(8,9)) * 3;
      xc = Number(cuil.substring(7,8)) * 4;
      xd = Number(cuil.substring(6,7)) * 5;
      xe = Number(cuil.substring(5,6)) * 6;
      xf = Number(cuil.substring(4,5)) * 7;
      xg = Number(cuil.substring(3,4)) * 2;
      xh = Number(cuil.substring(2,3)) * 3;
      xi = Number(cuil.substring(1,2)) * 4;
      xj = Number(cuil.substring(0,1)) * 5;

      //Suma los resultantes.
      x = xa + xb + xc + xd + xe + xf + xg + xh + xi + xj;

      //Calcula el dígito de control.  
      control = (11 - (x % 11)); 
      if (control==11){dv=0;}
      else  {
          if (control==10){dv=9;}
          else {dv=control;}
      }

      //Verifica si el dígito de control ingresado difiere con el calculado.
      if (dv == Number(cuil.substring(10,11))) {verifica=true;}		
      }
      return verifica;
  }	

   onCodigoError():String{
    var verifica,v1,v2,v3,v4,v5,v6,v7,v8,v9,v10,v11,v12,v13,v14:Boolean;
    v1=(String(this.newPersonaFisica.nroTributario).length==11 && this.isNumeric(String(this.newPersonaFisica.nroTributario)));
    v2=(String(this.newPersonaFisica.nroDocumento).length >=7 && this.isNumeric(String(this.newPersonaFisica.nroDocumento)));
    v3=true;//(String(this.newPersonaFisica.Apellido).length >=3);
    v4=true;//(String(this.newPersonaFisica.Nombre).length >=3);
    v5=(String(this.newPersonaFisica.fechaNacimiento).length ==10);
    v6=(String(this.newPersonaFisica.sexo).length ==1);
    v7=(String(this.newPersonaFisica.idNivelEducativo).length >=1);
    v8=(String(this.newPersonaFisica.idEstadoCivil).length >=1);
    v9=(String(this.newPersonaFisica.idNivelSE).length >=1);
    v10=(String(this.newPersonaFisica.idActividadEconAfip).length >=1); 
    v11=true; 
    v12=(String(this.newPersonaFisica.idLocalidad).length >=1);
   
    verifica=String(Number(v1)) + String(Number(v2)) + String(Number(v3)) + String(Number(v4)) + String(Number(v5)) + String(Number(v6)) + String(Number(v7)) + String(Number(v8)) + String(Number(v9)) + String(Number(v10)) + String(Number(v11)) + String(Number(v12));
   
    return verifica;
 }
 
 onLeyendaError(errorCode: String):String{
    var leyenda:String;
    leyenda='ERROR - NO SE PUEDE DAR ACTUALIZAR/CERTIFICAR LOS DATOS DE LA PERSONA. \nVERIFIQUE LOS SIGUIENTES CAMPOS: \n';
    if (errorCode.substr(0,1)=='0'){leyenda=leyenda + 'Nro Tributario \n' }
    if (errorCode.substr(1,1)=='0'){leyenda=leyenda + 'Nro de Documento \n' }
    if (errorCode.substr(2,1)=='0'){leyenda=leyenda + 'Apellido \n' }
    if (errorCode.substr(3,1)=='0'){leyenda=leyenda + 'Nombre \n' }
    if (errorCode.substr(4,1)=='0'){leyenda=leyenda + 'Fecha Nacimiento \n' }
    if (errorCode.substr(5,1)=='0'){leyenda=leyenda + 'Sexo \n' }
    if (errorCode.substr(6,1)=='0'){leyenda=leyenda + 'Nivel Educativo \n' }
    if (errorCode.substr(7,1)=='0'){leyenda=leyenda + 'Estado Civil \n' }
    if (errorCode.substr(8,1)=='0'){leyenda=leyenda + 'Nivel Socio-Economico \n' }
    if (errorCode.substr(9,1)=='0'){leyenda=leyenda + 'Actividad Económica \n' }
    if (errorCode.substr(10,1)=='0'){leyenda=leyenda + 'Nro Celular \n' }
    if (errorCode.substr(11,1)=='0'){leyenda=leyenda + 'Localidad \n' }
    return leyenda;
  }	

  isNumeric(k:String):Boolean{
    var r:Boolean=k.length>0;
    for (var i = 0; i <k.length ; i++) {r=r&&RegExp('[0-9]').test(k[i]);}
    return r;
  }

  onChange(){
    this.isChange=true;
    console.log('paso');
  }

}
