import { Component } from '@angular/core';
import {User} from './models/user';
import {MenuItem} from './models/menu-item';
import {globals} from './globals'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = globals.title;
  islogged$: boolean = false;
  userVO: User;
  menuItemSelectedVO: MenuItem = new MenuItem();

  listenEvenLogged(userLogged: User){
    this.islogged$=userLogged.isLogged;
    this.userVO=userLogged;
  }

  listenEvenMenuItemSelected(iMenuItemSelected: MenuItem){
    this.menuItemSelectedVO=iMenuItemSelected;
  }

  listenCloseEvent(iClose: boolean){
    if (iClose) {
        this.menuItemSelectedVO.claseGrupo='';
        this.menuItemSelectedVO.claseItem='';
        this.menuItemSelectedVO.grupo='';
        this.menuItemSelectedVO.item='';
        this.menuItemSelectedVO.subtitulo='';
        this.menuItemSelectedVO.titulo=''; 
    }
  }
}
