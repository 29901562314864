import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';

@Component({
  selector: 'app-emprendimientos-search',
  templateUrl: './emprendimientos-search.component.html'
})
export class EmprendimientosSearchComponent implements OnInit {


  @Input() iUserVO$: User;
  @Input() idPersonaFyJEmprendedor$: String;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  public criterio$: string='';
  public promotor: any={nickNamePromotorAlta:''};

  public selectedEmprendimiento: any={ idEmprendimiento: '',
                                    descEstadoEmprendimiento: '',
                                    descEmprendimiento: '',
                                    idPersonaFyJ: '',
                                    nroTributario: '',
                                    descPersonasFyJ: '',
                                    descEstadoEmprendedor: '',
                                    fechaAlta: '',
                                    fechaUltModificacion: '',
                                    fechaBaja: '',
                                    promotor:''
                                  };

  
  public urlAPI: string='https://3clcee2ns9.execute-api.us-east-2.amazonaws.com/beta/dataemprendimientos';                             
  public onShowForm: string=''; 
  public emprendimientos = [];
  public emprendimientosFiltro = []; 

   
  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { } 

  ngOnInit() {
    this.onShowForm='formEmprendimientos';
    this.cleanDatasets();
    this.criterio$='';
    this.emprendimientos = [];
    this.emprendimientosFiltro = [];
    if(this.iUserVO$.descRol=='Promotor'){
      this.getDataPromo();
    }
    if(this.iUserVO$.descRol=='Administrador'){
      this.getDataAdmin();
    } 
  }

  cleanDatasets(){
    this.selectedEmprendimiento={ idEmprendimiento: '',
                                    descEstadoEmprendimiento: '',
                                    descEmprendimiento: '',
                                    idPersonasFyJ: '',
                                    nroTributario: '',
                                    descPersonasFyJ: '',
                                    descEstadoEmprendedor: '',
                                    fechaAlta: '',
                                    fechaUltModificacion: '',
                                    fechaBaja: '',
                                    promotor:''};

  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  getDataAdmin(){
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.emprendimientos=respuesta['body'];
        if (String(this.idPersonaFyJEmprendedor$).length>0){
          this.emprendimientosFiltro=this.emprendimientos.filter(element => ((element.descEstadoEmprendimiento.toUpperCase()=='ACTIVO' || element.descEstadoEmprendimiento.toUpperCase()=='PLANIFICADO') && (String(element.idPersonasFyJ)==String(this.idPersonaFyJEmprendedor$))));
        }
        else {
          this.emprendimientosFiltro=this.emprendimientos;
        } 
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataPromo(){
    var respuesta: any;
    this.promotor.nickNamePromotorAlta=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/promotor',this.promotor)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.emprendimientos=respuesta['body'];
        if (String(this.idPersonaFyJEmprendedor$).length>0){
          this.emprendimientosFiltro=this.emprendimientos.filter(element => ((element.descEstadoEmprendimiento.toUpperCase()=='ACTIVO' || element.descEstadoEmprendimiento.toUpperCase()=='PLANIFICADO') && (String(element.idPersonasFyJ)==String(this.idPersonaFyJEmprendedor$))));
        }
        else {
          this.emprendimientosFiltro=this.emprendimientos;
        } 
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onSearchCriterio(){
    this.cleanDatasets();
    this.emprendimientosFiltro=[];
    if (String(this.idPersonaFyJEmprendedor$).length>0){
      this.emprendimientosFiltro=this.emprendimientos.filter(element => ((element.descEstadoEmprendimiento.toUpperCase()=='ACTIVO' || element.descEstadoEmprendimiento.toUpperCase()=='PLANIFICADO') && (String(element.idPersonasFyJ)==String(this.idPersonaFyJEmprendedor$))));
    }
    else {
      this.emprendimientosFiltro=this.emprendimientos.filter(element => element.descPersonasFyJ.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || String(element.nroTributario).indexOf(this.criterio$,0)>-1 || element.descEmprendimiento.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.promotor.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.fechaAlta.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1);
    }
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iEmprendimiento:any){
    this.selectedEmprendimiento=iEmprendimiento;
  }

  onAssign(){
    if ((this.selectedEmprendimiento.descEstadoEmprendedor=='ACTIVO')&&(this.selectedEmprendimiento.idEmprendimiento!='')) {
      this.assignEvent.emit(this.selectedEmprendimiento);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar un emprendimiento con un emprendedor activo');
    } 
  }


  onNew(){
    this.onShowForm='formNuevoEmprendimiento';
  }

  onEdit(){
    this.onShowForm='formEditarEmprendimiento';
  }


  listenCloseEvent(iMenuItemSelected: any){
    this.ngOnInit();
  }

}
