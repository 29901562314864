import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {AuthorizationService} from '../../services/authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class GetDataAPIService {

  data: any;

  constructor(private http: HttpClient,private auth: AuthorizationService) { }

  getDataAPIFetch(token: string,urlService: string):any{
    const headers = new Headers();
    headers.append('jwtToken',token);
    const init = {method:'GET',headers};
    return fetch(urlService, init);
  }

  postDataAPIFetch(token: string, urlService: string, data: any):any{
    const headers = new Headers();
    headers.append('jwtToken',token);
    const init = {method:'POST', headers, body:JSON.stringify(data)};
    //console.log(init);
    return fetch(urlService,init);
  }

  putDataAPIFetch(token: string, urlService: string, data: any):any{
    const headers = new Headers();
    headers.append('jwtToken',token);
    const init = {method:'PUT', headers, body:JSON.stringify(data)};
    //console.log(init);
    return fetch(urlService,init);
  }

}

