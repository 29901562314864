import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT,formatDate} from '@angular/common';
import { globals } from 'src/app/globals';
import  *  as  jsCanalesPago from  '../../../../assets/catalogo/jsCanalDersembolsoMP.json';


@Component({
  selector: 'app-pago-cuota',
  templateUrl: './pago-cuota.component.html'
})
export class PagoCuotaComponent implements OnInit {

  
  @Input() iUserVO$: User;
  @Input() iSelectedSolicitud: any;
  @Input() iLiquidacion: any;


  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';
  urlAPIPagos: string='https://wbbacm21t4.execute-api.us-east-2.amazonaws.com/beta/pagos';

  public pagos = [];

  ltCanalesPago: any = (jsCanalesPago as any).default;
  ltSituacion:any=[{id:'NO',descripcion:'NORMAL'},{id:'FG',descripcion:'FONDO GARANTIA'}];

  registroPago: any = {
    idSolicitud: '',
    idProdPrestamoLiq: '',
    fechaPago: '',
    nroOperacionTransf: '',
    monto: '',
    idCanalPago: '',
    nickNameUsuarioModif:''
  };

  maxDate:Date = new Date();

  
  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {  
    this.registroPago.idSolicitud='';
    this.registroPago.idProdPrestamoLiq='';
    this.registroPago.fechaPago=formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.registroPago.nroOperacionTransf='';
    this.registroPago.monto='';
    this.registroPago.idCanalPago='';
    this.registroPago.nickNameUsuarioModif='';
    this.onGetDataPagos();
  }

  

  onExit(){
    this.closeEvent.emit(true);
  }

  yyyymmdd(i:string):string{
    var r:string;
    var dd: string, mm:string, yyyy:string;
    var z: any;
    z=i.split('-');
    yyyy=z[0]; mm=z[1]; dd=z[2];
    if(dd.length==1){dd='0'+dd;}
    if(mm.length==1){mm='0'+mm;}
    r=yyyy+'-'+mm+'-'+dd;
    return r;
  }


  isNumeric(k:string):Boolean{
    var r:Boolean=false;
    var c:number=0;
    if(k!=undefined){
      r=k.length>0;
      for (var i = 0; i <k.length ; i++) {
        r=r&&((RegExp('[0-9]').test(k[i])) ||(k[i]=='.'));
        if (k[i]=='.'){c=c+1;}
        if (c>1){r=false;}
      }
    }
    return r;
  }

  isMoreTwoDecimal(k:string):Boolean{
    var r:Boolean=false;
    var c:number=0;
    if(k!=undefined){
      for (var i = 0; i <k.length ; i++) {
        if ((k[i]=='.')||(c>0)){c=c+1;}
      }
    }
    return (c>3);
  }

  menor(a:number,b:number):number{
    var r: number;
    if (a<b){r=a;}
    else {r=b;}
    return r;
  }

  onGetDataPagos(){
    var respuesta: any;
    this.iSelectedSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPagos,this.iSelectedSolicitud)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.pagos=respuesta['body'];
      }
      if (respuesta['statusCode']=='400'){   
        alert('ERROR - No se puede obtener los datos de pagos');
      }
    })
    .catch((e: any) => {
      // error in e.message
      //alert('Error: ' + e.message);
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }
  
  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onPagarMP(){
    var respuesta: any;
    this.registroPago.nickNameUsuarioModif=this.iUserVO$.user;
    this.registroPago.idSolicitud=this.iSelectedSolicitud.idSolicitud;
    this.registroPago.idProdPrestamoLiq=this.iLiquidacion.idProdPrestamoLiq;
    
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPagos + '/registrar',this.registroPago)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        alert('El pago se registro exitosamente');
        this.ngOnInit();
      }
      if (respuesta['statusCode']=='400'){   
        if (respuesta['body']=='1062'){ 
          alert('ERROR - El pago ya fue registrado');
        }
        else{
          alert('ERROR - No se pudo registrar el pago' + respuesta['body']);
        }
      }
    })
    .catch((e: any) => {
      // error in e.message
      //alert('Error: ' + e.message);
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }
  

  onPagar() {
    if (this.iSelectedSolicitud.descTipoProducto == 'MICROPRESTAMOS') {
      this.onPagarMP();
    }
    else {

    }
  }


}
