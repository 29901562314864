import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { HeaderComponent } from './components/shared/header/header.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { ContentComponent } from './components/shared/content/content.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SettingComponent } from './components/shared/setting/setting.component';
import { LoginComponent } from './components/shared/login/login.component';
import { PersonSearchComponent } from './components/pages/person-search/person-search.component';
import { PagosMicroSegurosComponent } from './components/pages/pagos-micro-seguros/pagos-micro-seguros.component';
import { PagosMicroSegurosAltaComponent } from './components/pages/pagos-micro-seguros-alta/pagos-micro-seguros-alta.component';
import { PersonNewFComponent } from './components/pages/person-new-f/person-new-f.component';
import { PersonNewJComponent } from './components/pages/person-new-j/person-new-j.component';
import { AfipActividadComponent } from './components/pages/afip-actividad/afip-actividad.component';
import { PersonEditFComponent } from './components/pages/person-edit-f/person-edit-f.component';
import { PersonEditJComponent } from './components/pages/person-edit-j/person-edit-j.component';
import { SolSolicitudesComponent } from './components/pages/sol-solicitudes/sol-solicitudes.component';
import { SolSolicitudesNewComponent } from './components/pages/sol-solicitudes-new/sol-solicitudes-new.component';
import { ProductosSearchComponent } from './components/pages/productos-search/productos-search.component';
import { EmprendimientosSearchComponent } from './components/pages/emprendimientos-search/emprendimientos-search.component';
import { EmprendimientosNewComponent } from './components/pages/emprendimientos-new/emprendimientos-new.component';
import { EmprendimientosEditComponent } from './components/pages/emprendimientos-edit/emprendimientos-edit.component';
import { SolSolicitudesEditComponent } from './components/pages/sol-solicitudes-edit/sol-solicitudes-edit.component';
import { AnalisisCrediticioComponent } from './components/pages/analisis-crediticio/analisis-crediticio.component';
import { AnalisisCrediticioEditComponent } from './components/pages/analisis-crediticio-edit/analisis-crediticio-edit.component';
import { LiquidarPrestamoComponent } from './components/pages/liquidar-prestamo/liquidar-prestamo.component';
import { ProdClieSearchComponent } from './components/pages/prod-clie-search/prod-clie-search.component';
import { ProdClienteDetalleComponent } from './components/pages/prod-cliente-detalle/prod-cliente-detalle.component';
import { PagosComponent } from './components/pages/pagos/pagos.component';
import { PagoCuotaComponent } from './components/pages/pago-cuota/pago-cuota.component';
import { DesembolsarPrestamoComponent } from './components/pages/desembolsar-prestamo/desembolsar-prestamo.component';
import { SplitComponent } from './components/pages/split/split.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    ContentComponent,
    FooterComponent,
    SettingComponent,
    LoginComponent,
    PersonSearchComponent,
    PagosMicroSegurosComponent,
    PagosMicroSegurosAltaComponent,
    PersonNewFComponent,
    PersonNewJComponent,
    AfipActividadComponent,
    PersonEditFComponent,
    PersonEditJComponent,
    SolSolicitudesComponent,
    SolSolicitudesNewComponent,
    ProductosSearchComponent,
    EmprendimientosSearchComponent,
    EmprendimientosNewComponent,
    EmprendimientosEditComponent,
    SolSolicitudesEditComponent,
    AnalisisCrediticioComponent,
    AnalisisCrediticioEditComponent,
    LiquidarPrestamoComponent,
    ProdClieSearchComponent,
    ProdClienteDetalleComponent,
    PagosComponent,
    PagoCuotaComponent,
    DesembolsarPrestamoComponent,
    SplitComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
