import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {MenuItem} from '../../../models/menu-item';
import {User} from '../../../models/user';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  menuItemPersona$: boolean = false;
  menuItemSolicitud$: boolean = false;
  menuItemRiesgoCredito$: boolean = false;
  menuItemProducto$: boolean = false;
  menuItemCobranza$: boolean = false;
  menuItemDashboard$: boolean = false;
  menuItemReportes$: boolean = false;
  menuItemEmprendimiento$: boolean = false;


  menuItemSelected: MenuItem = new MenuItem();

  @Input() iUserVO$: User;
  @Output() eventMenuSelected: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  constructor() { }

  
  ngOnInit () {
      
  }

 
  onMenuItemPPersonasFyJClick(){
    this.menuItemSelected.grupo='Personas';
    this.menuItemSelected.item='Físicas y Jurídicas';
    this.menuItemSelected.claseGrupo='fa  fa-users';
    this.menuItemSelected.claseItem='fa fa-user';
    this.menuItemSelected.titulo='Personas';
    this.menuItemSelected.subtitulo='Personas Físicas y Jurídicas';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }


  onMenuItemCGestionarCobranzaClick(){
    this.menuItemSelected.grupo='Cobranzas';
    this.menuItemSelected.item='Pagos y Cancelaciones';
    this.menuItemSelected.claseGrupo='fa fa-credit-card';
    this.menuItemSelected.claseItem='fa fa-money';
    this.menuItemSelected.titulo='Cobranzas';
    this.menuItemSelected.subtitulo='Pagos y Cancelaciones';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }

  onMenuItemPEmprendimientosClick(){
    this.menuItemSelected.grupo='Emprendimientos';
    this.menuItemSelected.item='Gestionar Emprendimientos';
    this.menuItemSelected.claseGrupo='fa fa-rocket';
    this.menuItemSelected.claseItem='fa fa-space-shuttle';
    this.menuItemSelected.titulo='Emprendimientos';
    this.menuItemSelected.subtitulo='Gestionar Emprendimientos';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }

  onMenuItemProductosClick(){
    this.menuItemSelected.grupo='Productos';
    this.menuItemSelected.item='Maestro de Productos';
    this.menuItemSelected.claseGrupo='fa fa-product-hunt';
    this.menuItemSelected.claseItem='fa fa-barcode';
    this.menuItemSelected.titulo='Productos';
    this.menuItemSelected.subtitulo='Maestro de Productos';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }

  
  onMenuItemSSolicitudesClick(){
    this.menuItemSelected.grupo='Solicitudes';
    this.menuItemSelected.item='Gestionar Solicitudes';
    this.menuItemSelected.claseGrupo='fa  fa-edit';
    this.menuItemSelected.claseItem='fa fa-gears';
    this.menuItemSelected.titulo='Solicitudes';
    this.menuItemSelected.subtitulo='Gestionar Solicitudes';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }

  onMenuItemAnalisisCreditoClick(){
    this.menuItemSelected.grupo='Originación';
    this.menuItemSelected.item='Procesar Solicitud';
    this.menuItemSelected.claseGrupo='fa fa-shield';
    this.menuItemSelected.claseItem='fa fa-sort-amount-desc';
    this.menuItemSelected.titulo='Originación';
    this.menuItemSelected.subtitulo='Procesar Solicitud';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }

  onMenuItemPClientesClick(){
    this.menuItemSelected.grupo='Personas';
    this.menuItemSelected.item='Clientes';
    this.menuItemSelected.claseGrupo='fa  fa-users';
    this.menuItemSelected.claseItem='fa fa-user-plus';
    this.menuItemSelected.titulo='Clientes';
    this.menuItemSelected.subtitulo='Clientes';
    this.eventMenuSelected.emit(this.menuItemSelected);
  }

  onMenuItemSMicroCreditosClick(){}
  onMenuItemRMicroCreditosClick(){}
  onMenuItemPMicroCreditosClick(){}
  onMenuItemPMicroSegurosClick(){}
  onMenuItemCMicroCreditosClick(){}
  onMenuItemDMicroCreditosClick(){}
  onMenuItemDMicroSegurosClick(){}
  onMenuItemReMicroCreditosClick(){}
  onMenuItemReMicroSegurosClick(){}


}
