import { Component, OnInit,Input} from '@angular/core';
import {User} from '../../../models/user';

@Component({
  selector: 'app-pagos-micro-seguros',
  templateUrl: './pagos-micro-seguros.component.html',
  styles: []
})
export class PagosMicroSegurosComponent implements OnInit {

  @Input() iUserVO$: User;

  public selectedPerson: any={Cuil:'',Name:'',Tipo:''};
  public showPersonas: boolean=false;
  public showPagosAlta: boolean=false;
  public showPagos: boolean=true;

  public selectedProducto: any={idContratoSegMed:'11231',
                                idPolizaSegMed:'1',
                                idLineaProducto:'1',
                                descLineaProducto:'MICROSEGURO FONCAP SANCOR',
                                idEstado:'2',
                                abrevEstado:'VIGENTE',
                                idtitular:'1',
                                descTitular:'ESCUDERO DARDO FABIAN',
                                idPersonasFyJAsegurable:'2',
                                descPersonasFyJAsegurable:'LIENDO IVANA GRACIELA',
                                idTipoGrupoFam:'2',
                                descTipoGrupoFam:'CONYUGE',
                                fechaAlta:'22/07/2020'};
  public pagos = [
    {periodo:'2020-07',fecha:'2020-07-05',valor:'50',liquidado:'NO'},
    {periodo:'2020-06',fecha:'2020-06-03',valor:'50',liquidado:'SI'},
    {periodo:'2020-05',fecha:'2020-05-04',valor:'50',liquidado:'SI'},
    {periodo:'2020-04',fecha:'2020-04-03',valor:'50',liquidado:'SI'},
    {periodo:'2020-03',fecha:'2020-03-08',valor:'50',liquidado:'SI'},
    {periodo:'2020-02',fecha:'2020-02-06',valor:'50',liquidado:'SI'},
    {periodo:'2020-01',fecha:'2020-01-05',valor:'50',liquidado:'SI'}
    ];

  constructor() { }

  ngOnInit() {
  }

  onShowModalPersonas(){
    this.showPagos=false;
    this.showPersonas=true;
    this.showPagosAlta=false;
  }

  onShowModalPagosAlta(){
    this.showPagos=false;
    this.showPersonas=false;
    this.showPagosAlta=true;
  }

   listenCloseEvent(iMenuItemSelected: any){
    this.showPagos=true;
    this.showPersonas=false;
    this.showPagosAlta=false;
  }

  listenAssignEvent(iPerson: any){
    this.selectedPerson=iPerson;
  }

}
