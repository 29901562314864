import { Component, OnInit,Output,Input,EventEmitter,Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import {User} from '../../../models/user';
import  *  as  jsAutomovil from  '../../../../assets/catalogo/jsAutomovil.json';
import  *  as  jsVivienda from  '../../../../assets/catalogo/jsVivienda.json';
import  *  as  jsEntidades from  '../../../../assets/catalogo/jsEntidades.json';
import  *  as  jsCanalDesembolsoMP from  '../../../../assets/catalogo/jsCanalDersembolsoMP.json';
import  *  as  jsTipoGrupoFam from  '../../../../assets/catalogo/jsTipoGrupoFam.json';



import { globals } from 'src/app/globals';

@Component({
  selector: 'app-sol-solicitudes-new',
  templateUrl: './sol-solicitudes-new.component.html'
})
export class SolSolicitudesNewComponent implements OnInit {

  
  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';
  urlAPIPromotores: string='https://akd7srf6f2.execute-api.us-east-2.amazonaws.com/beta/promotores';
  
  onShowForm: string='';
  
  ltHijos:any=[{id:0,descripcion:'0'},{id:1,descripcion:'1'},{id:2,descripcion:'2'},{id:3,descripcion:'3'},{id:4,descripcion:'4'},{id:5,descripcion:'5'},{id:6,descripcion:'6'},{id:7,descripcion:'7'},{id:8,descripcion:'8'},{id:9,descripcion:'9'},{id:10,descripcion:'10'}];
  ltsino:any=[{id:'S',descripcion:'S'},{id:'N',descripcion:'N'}];
  
  ltPromotores: any = [];
  ltAutomovil: any = (jsAutomovil as any).default;
  ltVivienda: any = (jsVivienda as any).default;
  ltEntidades: any = (jsEntidades as any).default;
  ltCanalesDesembolso: any = (jsCanalDesembolsoMP as any).default;
  ltTipoGrupoFam: any = (jsTipoGrupoFam as any).default;
  


  newSolicitud: any= {idLineaProducto:'',
                      idTipoProducto:'',
                      idProducto:'',
                      idPersonaFyJCliente:'',
                      idEmprendimiento:'',
                      nickNamePromotorAlta:'',
                      nickNameUsuarioAlta:'',
                      mpTasa:'',
                      mpCuotas:'',
                      mpValorCuota:'0',
                      mpMontoSolicitado:'',
                      mpAmortizacion:'',
                      mpGarantia01:'SOLIDARIA',
                      mpGarantia02:'SOLIDARIA',
                      mpGaranteSol01IdPFyJ:'',
                      mpGaranteSol02IdPFyJ:'',
                      mpIngresosFamiliares:'',
                      mpGastosFamiliares:'',
                      mpIngresosEmprendimientos:'',
                      mpGastosEmprendimientos:'',
                      mpJefasHogar:'N',
                      mpQHijos:0,
                      mpAutomovil:1,
                      mpVivienda:1,
                      mpEntidad:0,
                      mpIdCanalDesembolso:1,
                      mpCBU:'',
                      msTitular:'',
                      msIdTipoGrupoFam:1,
                      mpTasaPunitorios:''
                    };

  selectedProducto: any={idTipoProducto : '',
                      descTipoProducto : '',
                      idProducto : '',
                      descProducto : '',
                      idLineaProducto : '',
                      descLineaProducto : '',
                      descTasa: '',
                      tasa : '',
                      tasaPunitorios:'',
                      cuotas : '',
                      amortizacion : '',
                      emprendedor : '',
                      prima:'',
                      impuestos: '',
                      premio: '',
                      activo : '',
                      visible : ''};

  selectedEmprendimiento: any={ idEmprendimiento: '',
                      descEstadoEmprendimiento: '',
                      descEmprendimiento: '',
                      nroTributario: '',
                      descPersonasFyJ: '',
                      fechaAlta: '',
                      fechaUltModificacion: '',
                      fechaBaja: ''};

                    

  selectedCliente: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
  selectedGaranteSol01: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
  selectedGaranteSol02: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
  selectedTitularMS: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:''};
  itemPromotor: any;

  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  onClearSelected(){
    this.newSolicitud = {idLineaProducto:'',
                      idTipoProducto:'',
                      idProducto:'',
                      idPersonaFyJCliente:'',
                      idEmprendimiento:'',
                      nickNamePromotorAlta:'',
                      nickNameUsuarioAlta:'',
                      mpTasa:'',
                      mpCuotas:'',
                      mpValorCuota:'0',
                      mpMontoSolicitado:'',
                      mpAmortizacion:'',
                      mpGarantia01:'SOLIDARIA',
                      mpGarantia02:'SOLIDARIA',
                      mpGaranteSol01IdPFyJ:'',
                      mpGaranteSol02IdPFyJ:'',
                      mpIngresosFamiliares:'',
                      mpGastosFamiliares:'',
                      mpIngresosEmprendimientos:'',
                      mpGastosEmprendimientos:'',
                      mpJefasHogar:'N',
                      mpQHijos:0,
                      mpAutomovil:1,
                      mpVivienda:1,
                      mpEntidad:0,
                      mpIdCanalDesembolso:1,
                      mpCBU:'',
                      msTitular:'',
                      msIdTipoGrupoFam:1,
                      mpTasaPunitorios:'',
                    };

    this.selectedProducto={idTipoProducto : '',
                    descTipoProducto : '',
                    idProducto : '',
                    descProducto : '',
                    idLineaProducto : '',
                    descLineaProducto : '',
                    descTasa: '',
                    tasa : '',
                    tasaPunitorios:'',
                    cuotas : '',
                    amortizacion : '',
                    emprendedor : '',
                    prima:'',
                    impuestos: '',
                    premio: '',
                    activo : '',
                    visible : ''};

    this.selectedEmprendimiento={ idEmprendimiento: '',
                    descEstadoEmprendimiento: '',
                    descEmprendimiento: '',
                    nroTributario: '',
                    descPersonasFyJ: '',
                    fechaAlta: '',
                    fechaUltModificacion: '',
                    fechaBaja: ''};

    this.selectedCliente={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
    this.selectedGaranteSol01={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
    this.selectedGaranteSol02={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
    this.selectedTitularMS={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:''};
    this.itemPromotor=null;    
  }

  ngOnInit() {
    this.onClearSelected();
    this.onShowForm='formNewSolicitud';
    this.itemPromotor=this.ltPromotores.find(item => item.nickName===this.iUserVO$.user);
    if(this.itemPromotor!=null){
      if (this.itemPromotor.nickName==this.iUserVO$.user){
        this.newSolicitud.nickNamePromotorAlta=this.iUserVO$.user;
      }
    }
    this.newSolicitud.nickNameUsuarioAlta=this.iUserVO$.user;
    this.onCalValues();
    this.getDataPromotores();
  }

  getDataPromotores(){
    var respuesta: any;  
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPromotores)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.ltPromotores=respuesta['body']['activos'];
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  listenAssignEventProduct(iProducto: any){
    this.selectedProducto=iProducto;
    this.newSolicitud.idLineaProducto=this.selectedProducto.idLineaProducto;
    this.newSolicitud.idTipoProducto=this.selectedProducto.idTipoProducto;
    this.newSolicitud.idProducto=this.selectedProducto.idProducto;
    if(this.selectedProducto.idTipoProducto==1){
      this.newSolicitud.mpTasa=this.selectedProducto.tasa;
      this.newSolicitud.mpTasaPunitorios=this.selectedProducto.tasaPunitorios;
      this.newSolicitud.mpAmortizacion=this.selectedProducto.amortizacion;
      this.onCalValues();
    }
  }

  listenAssignEventCliente(iCliente: any){
    this.selectedCliente=iCliente;
    this.newSolicitud.idPersonaFyJCliente=this.selectedCliente.idPersonasFyJ;
    this.onChangeGrupoFam();
  }


  listenAssignEventGaranteSol01(iGarante: any){
    this.selectedGaranteSol01=iGarante;
    this.newSolicitud.mpGaranteSol01IdPFyJ=this.selectedGaranteSol01.idPersonasFyJ;
  }

  listenAssignEventGaranteSol02(iGarante: any){
    this.selectedGaranteSol02=iGarante;
    this.newSolicitud.mpGaranteSol02IdPFyJ=this.selectedGaranteSol02.idPersonasFyJ;
  }

  listenAssignEventEmprendimiento(iEmprendimiento: any){
    this.selectedEmprendimiento=iEmprendimiento;
    this.newSolicitud.idEmprendimiento=this.selectedEmprendimiento.idEmprendimiento;
  }

  listenAssignEventTitularMS(iTitular: any){
    this.selectedTitularMS=iTitular;
    this.newSolicitud.msTitular=this.selectedTitularMS.idPersonasFyJ;
  }

  onExit(){
    //console.log(JSON.stringify(this.newPersonaFisica));
    this.closeEvent.emit(true);
  }
  
  onSetFormShow(iForm: any){
    if(iForm=='formEmprendimientoSearch'){
      if(this.selectedCliente.idPersonasFyJ!=''){
        this.onShowForm=iForm;  
      }
      else{
        alert('Seleccione un Cliente');
      }
    }
    else {
      this.onShowForm=iForm;
    }
    
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='formNewSolicitud';
  }

  onChageGarantia01(iGarantia: String){
    this.newSolicitud.mpGarantia01=iGarantia;
  }

  onChageGarantia02(iGarantia: String){
    this.newSolicitud.mpGarantia02=iGarantia;
  }

  onCodigoError():string{
    var verifica,v1,v2,v3,v4,v5,v6,v7,v8,v9,v10,v11,v12,v13,v14,v15,v16:Boolean;
    if (this.newSolicitud.idTipoProducto==1){ 
      v1=(String(this.newSolicitud.idLineaProducto)!='');
      v2=(String(this.newSolicitud.idPersonaFyJCliente)!='');
      v3=true; //(String(this.newSolicitud.idEmprendimiento)!='');
      v4=(String(this.newSolicitud.nickNamePromotorAlta)!='');
      v5=(String(this.newSolicitud.mpMontoSolicitado)!='' && this.isNumeric(String(this.newSolicitud.mpMontoSolicitado))); 
      v6=(String(this.newSolicitud.mpCuotas).length>=1);
      v7=true;//((String(this.newSolicitud.mpGaranteSol01).length >=1)&&(String(this.newSolicitud.mpGaranteSol02).length >=1));
      v8=((String(this.newSolicitud.mpIngresosFamiliares).length >=1) && this.isNumeric(String(this.newSolicitud.mpIngresosFamiliares))); 
      v9=((String(this.newSolicitud.mpGastosFamiliares).length >=1) && this.isNumeric(String(this.newSolicitud.mpGastosFamiliares))); 
      v10=((String(this.newSolicitud.mpIngresosEmprendimientos).length >=1) && this.isNumeric(String(this.newSolicitud.mpIngresosEmprendimientos))); 
      v11=((String(this.newSolicitud.mpGastosEmprendimientos).length >=1) && this.isNumeric(String(this.newSolicitud.mpGastosEmprendimientos))); 
      v12=(String(this.newSolicitud.mpAutomovil).length >=1);
      v13=(String(this.newSolicitud.mpVivienda).length >=1);
      v14=(String(this.newSolicitud.mpEntidad).length >=1);
      v15=(String(this.newSolicitud.mpIdCanalDesembolso).length >=1);

      if(this.newSolicitud.mpIdCanalDesembolso==3 || this.newSolicitud.mpIdCanalDesembolso==4){v16=this.validarCBU(String(this.newSolicitud.mpCBU));}
      else{v16=true;}
      
      verifica=String(Number(v1)) + String(Number(v2)) + String(Number(v3)) + String(Number(v4)) + String(Number(v5)) + String(Number(v6)) + String(Number(v7)) + String(Number(v8)) + String(Number(v9)) + String(Number(v10)) + String(Number(v11)) + String(Number(v12)) + String(Number(v13)) + String(Number(v14)) + String(Number(v15)) + String(Number(v16));
    }
    if (this.newSolicitud.idTipoProducto==2){ 
      v1=(String(this.newSolicitud.idLineaProducto)!='');
      v2=(String(this.newSolicitud.idPersonaFyJCliente)!='');
      v3=(String(this.newSolicitud.nickNamePromotorAlta)!='');
      v4=(String(this.newSolicitud.msIdTipoGrupoFam).length >=1);
      v5=(String(this.newSolicitud.msTitular).length >=1);
      verifica=String(Number(v1)) + String(Number(v2)) + String(Number(v3)) + String(Number(v4)) + String(Number(v5));
    }
    return verifica;
 }
 
 onLeyendaError(errorCode: string):string{
    var leyenda:string;
    leyenda='ERROR - NO SE PUEDE DAR DE ALTA LA SOLICITUD. \nVERIFIQUE LOS SIGUIENTES CAMPOS: \n';
    if (this.newSolicitud.idTipoProducto==1){ 
      if (errorCode.substr(0,1)=='0'){leyenda=leyenda + 'Producto \n' }
      if (errorCode.substr(1,1)=='0'){leyenda=leyenda + 'Cliente \n' }
      if (errorCode.substr(2,1)=='0'){leyenda=leyenda + 'Emprendimiento \n' }
      if (errorCode.substr(3,1)=='0'){leyenda=leyenda + 'Promotor \n' }
      if (errorCode.substr(4,1)=='0'){leyenda=leyenda + 'Monto \n' }
      if (errorCode.substr(5,1)=='0'){leyenda=leyenda + 'Cuotas \n' }
      if (errorCode.substr(6,1)=='0'){leyenda=leyenda + 'Garantia \n' }
      if (errorCode.substr(7,1)=='0'){leyenda=leyenda + 'Ingresos Familiares \n' }
      if (errorCode.substr(8,1)=='0'){leyenda=leyenda + 'Gastos Familiares \n' }
      if (errorCode.substr(9,1)=='0'){leyenda=leyenda + 'Ingresos Emprendimientos \n' }
      if (errorCode.substr(10,1)=='0'){leyenda=leyenda + 'Gastos Emprendimientos \n' }
      if (errorCode.substr(11,1)=='0'){leyenda=leyenda + 'Automovil \n' }
      if (errorCode.substr(12,1)=='0'){leyenda=leyenda + 'Vivienda \n' }
      if (errorCode.substr(13,1)=='0'){leyenda=leyenda + 'Banco Principal \n' }
      if (errorCode.substr(14,1)=='0'){leyenda=leyenda + 'Canal de Pago \n' }
      if (errorCode.substr(15,1)=='0'){leyenda=leyenda + 'CBU \n' }
    }

    if (this.newSolicitud.idTipoProducto==2){ 
      if (errorCode.substr(0,1)=='0'){leyenda=leyenda + 'Producto \n' }
      if (errorCode.substr(1,1)=='0'){leyenda=leyenda + 'Cliente \n' }
      if (errorCode.substr(2,1)=='0'){leyenda=leyenda + 'Promotor \n' }
      if (errorCode.substr(3,1)=='0'){leyenda=leyenda + 'Grupo Familiar \n' }
      if (errorCode.substr(4,1)=='0'){leyenda=leyenda + 'Titular \n' }
    }

    return leyenda;
  }	

  isNumeric(k:string):Boolean{
    var r:Boolean=k.length>0;
    for (var i = 0; i <k.length ; i++) {r=r&&RegExp('[0-9]').test(k[i])}
    return r;
  }

  validarLargoCBU(numero:string):Boolean {
    if (numero.length != 22) { return false; }
    return true;
  }
  
  validarBloque1(bloque1: string):Boolean {
    if (bloque1.length != 8) { return false; }
    var banco = bloque1.substr(0,3);
    var digitoVerificador1 = bloque1[3];
    var sucursal = bloque1.substr(4,3);
    var digitoVerificador2 = bloque1[7];
    var suma = Number(banco[0]) * 7 + Number(banco[1]) * 1 + Number(banco[2]) * 3 + Number(digitoVerificador1) * 9 + Number(sucursal[0]) * 7 + Number(sucursal[1]) * 1 + Number(sucursal[2]) * 3;
    var diferencia = 10 - (suma % 10);
    console.log(diferencia);
    console.log(digitoVerificador2);
    if (Number(digitoVerificador2)==0){return (Number(diferencia) == Number(10));}
	  else {return (Number(diferencia) == Number(digitoVerificador2));}
  }
  
  validarBloque2(bloque2: string):Boolean {
    if (bloque2.length != 14) { return false; }
    var digitoVerificador = bloque2[13];
    var suma = Number(bloque2[0]) * 3 + Number(bloque2[1]) * 9 + Number(bloque2[2]) * 7 + Number(bloque2[3]) * 1 + Number(bloque2[4]) * 3 + Number(bloque2[5]) * 9 + Number(bloque2[6]) * 7 + Number(bloque2[7]) * 1 + Number(bloque2[8]) * 3 + Number(bloque2[9]) * 9 + Number(bloque2[10]) * 7 + Number(bloque2[11]) * 1 + Number(bloque2[12]) * 3;
    var diferencia = 10 - (suma % 10);
    if (Number(digitoVerificador)==0){return (Number(diferencia) == Number(10));}
	  else{return (Number(diferencia) == Number(digitoVerificador));}
  }
  
  validarCBU(nrocbu: string) {
    return this.validarLargoCBU(nrocbu) && this.validarBloque1(nrocbu.substr(0,8)) && this.validarBloque2(nrocbu.substr(8,14));
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }
  
  onAceptar(){
    var codigoError: String;
    if (this.newSolicitud.idTipoProducto==1){codigoError='1111111111111111';}
    if (this.newSolicitud.idTipoProducto==2){codigoError='11111';}
    
    if (this.onCodigoError()==codigoError){
        var respuesta: any;
        this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI,this.newSolicitud)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta=JSON.parse(data);
          if (respuesta['statusCode']=='200'){   
            alert('La Solicitud se registró exitosamente');
            this.onExit();
          }
          if (respuesta['statusCode']=='400'){   
            alert('ERROR - No se puede registrar la solicitud');
          }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    }
    else{
      alert(this.onLeyendaError(this.onCodigoError()));
    }
  }

  onChangeGrupoFam() {
    if (this.newSolicitud.msIdTipoGrupoFam == 1) {
      this.selectedTitularMS = this.selectedCliente;
      this.newSolicitud.msTitular = this.selectedTitularMS.idPersonasFyJ;
    }
    else {
      if (this.newSolicitud.msTitular == this.selectedCliente.idPersonasFyJ) {
        this.selectedTitularMS = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
        this.newSolicitud.msTitular ='';
      }
    }
  }

  onCalValues(){
    var tem:number;
    tem=(Number(this.newSolicitud.mpTasa)/100.0)/12.0;
    //this.newSolicitud.rieValorCuota=Math.round(Number(this.newSolicitud.mpMontoSolicitado)*((tem)/(1-Math.pow((1+Number(tem)),-1*Number(this.newSolicitud.mpCuotas)))));   
    
    this.newSolicitud.mpValorCuota=Math.round(Number(this.newSolicitud.mpMontoSolicitado)*((Math.pow((1+tem),Number(this.newSolicitud.mpCuotas))*tem)/(Math.pow((1+tem),Number(this.newSolicitud.mpCuotas))-1)));
   
    if (!isFinite(this.newSolicitud.mpValorCuota) || isNaN(this.newSolicitud.mpValorCuota)){this.newSolicitud.mpValorCuota=0;}
  }

  setCertificado(iState:String):String{
    var respuesta: String;
    if(iState=='CERTIFICADO'){respuesta='label label-success pull-rigth';}
    if(iState=='NO CERTIFICADO'){respuesta='label label-danger pull-rigth';}
    return respuesta;
  }
}
