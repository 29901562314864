import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import {User} from '../../../models/user';


@Component({
  selector: 'app-productos-search',
  templateUrl: './productos-search.component.html'
})
export class ProductosSearchComponent implements OnInit {

  urlAPI: string='https://vgag5qc5i8.execute-api.us-east-2.amazonaws.com/beta/datosestructuraproducto';

  public criterio$: string='';
  public blankProducto: any={idTipoProducto : '',
                                  descTipoProducto : '',
                                  idProducto : '',
                                  descProducto : '',
                                  idLineaProducto : '',
                                  descLineaProducto : '',
                                  descTasa: '',
                                  tasa : '',
                                  cuotas : '',
                                  amortizacion : '',
                                  activo : '',
                                  visible : ''};

  public selectedProducto: any= this.blankProducto;
  public onShowForm: String;

  public productos = [];
  public productosFiltro = [];
  
  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() idTipoProducto$: Number;

  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.onShowForm='formProductos';
    this.getData();
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  getData(){
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if ((respuesta['statusCode']=='200') && (respuesta['body']!=[])){   
        this.productos=respuesta['body'];
        this.productosFiltro=this.productos;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onSearchCriterio(){
    this.selectedProducto=this.blankProducto;
    this.productosFiltro =[];
    if (this.idTipoProducto$==0){
      this.productosFiltro=this.productos.filter(element => ((element.descProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1)||(element.descLineaProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1)));
    }
    else{
      this.productosFiltro=this.productos.filter(element => (((element.descProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1)||(element.descLineaProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1))&&(element.idTipoProducto==this.idTipoProducto$)));
    }
    
  }

  onSearchCriterioActivo(iState : string){
    this.selectedProducto=this.blankProducto;
    this.productosFiltro =[];
    this.criterio$='';
    if (this.idTipoProducto$==0){
      this.productosFiltro=this.productos.filter(element => (element.activo==iState));
    }
    else{
      this.productosFiltro=this.productos.filter(element => ((element.activo==iState)&&(element.idTipoProducto==this.idTipoProducto$)));
    }
    
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iProducto:any){
    if (iProducto.activo==1) {
      this.selectedProducto=iProducto;
    } 
    else{
      alert('Debe seleccionar un producto activo');
    } 
  }

  onAssign(){
    if (this.selectedProducto.activo==1) {
      this.assignEvent.emit(this.selectedProducto);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar un producto activo');
    } 
  }

  onFilterState(iState:string){
    if (iState=='TODOS') {
      this.criterio$='';
      this.onSearchCriterio();
    }
    else {this.onSearchCriterioActivo(iState);}
    
  }

}
