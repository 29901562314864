import { Component, OnInit,Output,Input,EventEmitter,Inject} from '@angular/core';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import {User} from '../../../models/user';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import  *  as  jsLocalidades  from  '../../../../assets/catalogo/jsLocalidades.json';
import  *  as  jsTiposPersonasJuridicas from  '../../../../assets/catalogo/jsTiposPersonasJuridicas.json';


@Component({
  selector: 'app-person-new-j',
  templateUrl: './person-new-j.component.html'
})
export class PersonNewJComponent implements OnInit {

  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string='https://fxssoz4ri7.execute-api.us-east-2.amazonaws.com/beta/datapersonasjuridicas';
  urlAPIPromotores: string='https://akd7srf6f2.execute-api.us-east-2.amazonaws.com/beta/promotores';
  
  onShowForm: string='';
  editPromotor: boolean;
  

  ltLocalidades: any = (jsLocalidades as any).default;
  ltTiposPersonasJuridicas: any = (jsTiposPersonasJuridicas as any).default;
  ltPromotores: any = [];

  newPersonaJuridica: any= {nroTributario: '',
                            razonSocial: '',
                            idTipoPersonaJuridica: '',
                            idActividadEconAfip: '',
                            email: '',
                            nroTelefono: '',
                            nroInterno: '',
                            nroCelular: '',
                            idLocalidad: '',
                            calle: '',
                            numero: '',
                            piso: '',
                            depto: '',
                            manzana: '',
                            casa: '',
                            barrio: '',
                            nickNamePromotorAlta:'',
                            nickNameUsuarioAlta:''};
                            
  selectedActividadEconomica: any={ idActividadEconAfip : '',
                                    codActividadF883 : '',
                                    descActividadF883 : '',
                                    desclActividadF883 : ''};



  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.getDataPromotores();
    
    
  }

  onClear(){
      this.newPersonaJuridica = { nroTributario: '',
                                  razonSocial: '',
                                  idTipoPersonaJuridica: '',
                                  idActividadEconAfip: '',
                                  email: '',
                                  nroTelefono: '',
                                  nroInterno: '',
                                  nroCelular: '',
                                  idLocalidad: '',
                                  calle: '',
                                  numero: '',
                                  piso: '',
                                  depto: '',
                                  manzana: '',
                                  casa: '',
                                  barrio: '',
                                  nickNamePromotorAlta:'',
                                  nickNameUsuarioAlta:''};

    this.selectedActividadEconomica={ idActividadEconAfip : '',
                                      codActividadF883 : '',
                                      descActividadF883 : '',
                                      desclActividadF883 : ''};

    this.newPersonaJuridica.nickNameUsuarioAlta=this.iUserVO$.user;
  }

  getDataPromotores(){
    var respuesta: any;  var itemPromotor: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPromotores)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.ltPromotores=respuesta['body']['activos'];
        itemPromotor=this.ltPromotores.find(item => item.nickName===this.iUserVO$.user);
        if(itemPromotor!=null){
          if ((itemPromotor.nickName==this.iUserVO$.user) && (this.iUserVO$.descRol=='Promotor')){
            this.newPersonaJuridica.nickNamePromotorAlta=this.iUserVO$.user;
          }
        }
        this.editPromotor=(this.iUserVO$.descRol=='Promotor');
        this.newPersonaJuridica.nickNameUsuarioAlta=this.iUserVO$.user;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onExit(){
    //console.log(JSON.stringify(this.newPersonaFisica));
    this.closeEvent.emit(true);
  }

  onSetFormShow(iForm: string){
    this.onShowForm=iForm;
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='';
  }

  listenAssignEvent(iActividadEconomica: any){
    this.selectedActividadEconomica=iActividadEconomica;
    this.newPersonaJuridica.idActividadEconAfip=String(this.selectedActividadEconomica.idActividadEconAfip);

  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onAceptar(){
    if (this.onCodigoError()=='1111111'){
      if (this.verificarCuil(String(this.newPersonaJuridica.nroTributario))){
        var respuesta: any;
        this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI,this.newPersonaJuridica)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta=JSON.parse(data);
          if (respuesta['statusCode']=='200'){   
            alert('La persona se registró exitosamente');
            this.onClear();
          }
          if ((respuesta['statusCode']=='400') && (respuesta['body']=='1062')){   
            alert('ERROR - La persona ya se encuentra registrada');
          }
          if ((respuesta['statusCode']=='400') && (respuesta['body']!='1062')){   
            alert('ERROR - No se puede registrar a la persona, revise los datos OBLIGATORIOS');
          }
        })
        .catch((e: any) => {
          // error in e.message
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
      }
      else{
        alert('ERROR - Número Tributario inválido');
      }
    }
    else{
      alert(this.onLeyendaError(this.onCodigoError()));
    }
  }

  verificarCuil(cuil:string):Boolean{
    var verifica:Boolean=false;
    var control,x,xa,xb,xc,xd,xe,xf,xg,xh,xi,xj,dv: number;
    
    if (cuil.length == 11) {
      //Individualiza y multiplica los dígitos.
      xa = Number(cuil.substring(9,10)) * 2;
      xb = Number(cuil.substring(8,9)) * 3;
      xc = Number(cuil.substring(7,8)) * 4;
      xd = Number(cuil.substring(6,7)) * 5;
      xe = Number(cuil.substring(5,6)) * 6;
      xf = Number(cuil.substring(4,5)) * 7;
      xg = Number(cuil.substring(3,4)) * 2;
      xh = Number(cuil.substring(2,3)) * 3;
      xi = Number(cuil.substring(1,2)) * 4;
      xj = Number(cuil.substring(0,1)) * 5;

      //Suma los resultantes.
      x = xa + xb + xc + xd + xe + xf + xg + xh + xi + xj;

      //Calcula el dígito de control.  
      control = (11 - (x % 11)); 
      if (control==11){dv=0;}
      else  {
          if (control==10){dv=9;}
          else {dv=control;}
      }

      //Verifica si el dígito de control ingresado difiere con el calculado.
      if (dv == Number(cuil.substring(10,11))) {verifica=true;}		
      }
      return verifica;
  }	

   onCodigoError():string{
    var verifica,v1,v2,v3,v4,v5,v6,v7,v8,v9,v10,v11,v12,v13,v14:Boolean;
    v1=(String(this.newPersonaJuridica.nroTributario).length==11 && this.isNumeric(String(this.newPersonaJuridica.nroTributario)));
    v2=(String(this.newPersonaJuridica.razonSocial).length >=3);
    v3=(String(this.newPersonaJuridica.idActividadEconAfip).length >=1);
    v4=(String(this.newPersonaJuridica.nroCelular).length >=9  && this.isNumeric(String(this.newPersonaJuridica.nroCelular))); 
    v5=(String(this.newPersonaJuridica.idLocalidad).length >=1);
    v6=(String(this.newPersonaJuridica.idTipoPersonaJuridica).length >=1);
    v7=(String(this.newPersonaJuridica.nickNamePromotorAlta).length >=1);
   
    verifica=String(Number(v1)) + String(Number(v2)) + String(Number(v3)) + String(Number(v4)) + String(Number(v5))+ String(Number(v6)) + String(Number(v7));
    return verifica;
 }
 
 onLeyendaError(errorCode: string):string{
    var leyenda:string;
    leyenda='ERROR - NO SE PUEDE DAR DE ALTA LA PERSONA. \nVERIFIQUE LOS SIGUIENTES CAMPOS: \n';
    if (errorCode.substr(0,1)=='0'){leyenda=leyenda + 'Nro Tributario \n' }
    if (errorCode.substr(1,1)=='0'){leyenda=leyenda + 'Razón Social \n' }
    if (errorCode.substr(2,1)=='0'){leyenda=leyenda + 'Actividad Económica \n' }
    if (errorCode.substr(3,1)=='0'){leyenda=leyenda + 'Nro Celular \n' }
    if (errorCode.substr(4,1)=='0'){leyenda=leyenda + 'Localidad \n' }
    if (errorCode.substr(5,1)=='0'){leyenda=leyenda + 'Tipo Persona Jurídica \n' }
    if (errorCode.substr(6,1)=='0'){leyenda=leyenda + 'Promotor \n' }
    return leyenda;
  }	

  isNumeric(k:string):Boolean{
    var r:Boolean=k.length>0;
    for (var i = 0; i <k.length ; i++) {r=r&&RegExp('[0-9]').test(k[i])}
    return r;
  }


}
