import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';

@Component({
  selector: 'app-sol-solicitudes',
  templateUrl: './sol-solicitudes.component.html'
})
export class SolSolicitudesComponent implements OnInit {

  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  public criterio$: string='';
  public promotor: any={nickNamePromotorAlta:''};


  public cleanSolicitud: any={idSolicitud: '',
                                idLineaProducto: '',
                                descLineaProducto: '',
                                idProducto: '',
                                descProducto: '',
                                idTipoProducto: '',
                                descTipoProducto: '',
                                idEstado: '',
                                descEstado: '',
                                abervEstado: '',
                                nroTributarioCliente: '',
                                descCliente: '',
                                fechaAlta: '',
                                fechaBaja: '',
                                descUsuario: ''};

  public selectedSolicitud:any = this.cleanSolicitud;
  
  public urlAPI: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';                             
  public onShowForm: string=''; 
  public solicitudes = [];
  public solicitudesFiltro = []; 
  public stdSolicitudesEstado = [];

   
  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { } 

  ngOnInit() {
    this.onShowForm='formSolicitudes';
    this.selectedSolicitud=this.cleanSolicitud;
    this.criterio$='';
    this.solicitudes = [];
    this.solicitudesFiltro = [];
    if(this.iUserVO$.descRol=='Promotor'){
      this.getDataPromo();
      this.getStdTotalesSolicitudes(this.iUserVO$.user);
    }
    if(this.iUserVO$.descRol=='Administrador'){
      this.getDataAdmin();
      this.getStdTotalesSolicitudes('');
    } 
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  getDataAdmin(){
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.solicitudes=respuesta['body'];
        this.solicitudesFiltro=this.solicitudes;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getStdTotalesSolicitudes(iUser:string){
    var respuesta: any;
    this.promotor.nickNamePromotorAlta=iUser;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/count',this.promotor)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.stdSolicitudesEstado=respuesta['body'];
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataPromo(){
    var respuesta: any;
    this.promotor.nickNamePromotorAlta=this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/usuario',this.promotor)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.solicitudes=respuesta['body'];
        this.solicitudesFiltro=this.solicitudes;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onSearchCriterio(){
    this.selectedSolicitud=this.cleanSolicitud;
    this.solicitudesFiltro =[];
    this.solicitudesFiltro=this.solicitudes.filter(element => element.abervEstado.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.descCliente.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || String(element.nroTributarioCliente).indexOf(this.criterio$,0)>-1 || String(element.idSolicitud).indexOf(this.criterio$,0)>-1 || element.descLineaProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.descUsuario.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.fechaAlta.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1);
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iSolicitud:any){
    this.selectedSolicitud=iSolicitud;
  }

  onAssign(){
    if (this.selectedSolicitud.idSolicitud!='') {
      this.assignEvent.emit(this.selectedSolicitud);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar una solicitud');
    } 
  }

  setState(iState:String):String{
    var respuesta: String;
    if(iState=='PRECARGA'){respuesta='label label-default pull-right';}
    if(iState=='APROBADA'){respuesta='label label-success pull-right';}
    if(iState=='BAJA'){respuesta='label label-info pull-right';}
    if(iState=='ANULADA'){respuesta='label label-info pull-right';}
    if(iState=='VIGENTE'){respuesta='label label-primary pull-right';}
    if(iState=='CANCELADA'){respuesta='label label-primary pull-right';}
    if(iState=='MORA'){respuesta='label label-warning pull-right';}
    if(iState=='PENDIENTE'){respuesta='label label-warning pull-right';}
    if(iState=='RECHAZADA'){respuesta='label label-danger pull-right';}
    if(iState=='DESEMBOLSADA'){respuesta='label label-success pull-right';}
    if(iState=='INCOBRABLE'){respuesta='label label-danger pull-right';}
    if(iState=='RECUPERO'){respuesta='label label-warning pull-right';}
    if(iState=='OPORTUNIDAD'){respuesta='label label-info pull-right';}
    if(iState=='FUERA ZONA'){respuesta='label label-danger pull-right';}
    return respuesta;
  }

  setStateTotal(iState:String):String{
    var respuesta: String;
    if(iState=='PRECARGA'){respuesta='badge bg-gray';}
    if(iState=='APROBADA'){respuesta='badge bg-green';}
    if(iState=='BAJA'){respuesta='badge bg-red';}
    if(iState=='ANULADA'){respuesta='badge bg-aqua';}
    if(iState=='VIGENTE'){respuesta='badge bg-green';}
    if(iState=='CANCELADA'){respuesta='badge bg-aqua';}
    if(iState=='MORA'){respuesta='badge bg-red';}
    if(iState=='PENDIENTE'){respuesta='badge bg-yellow';}
    if(iState=='RECHAZADA'){respuesta='badge bg-red';}
    if(iState=='DESEMBOLSADA'){respuesta='badge bg-green';}
    if(iState=='INCOBRABLE'){respuesta='badge bg-red';}
    if(iState=='RECUPERO'){respuesta='badge bg-yellow';}
    if(iState=='OPORTUNIDAD'){respuesta='badge bg-aqua';}
    if(iState=='FUERA ZONA'){respuesta='badge bg-red';}
    if(iState=='TOTAL'){respuesta='badge bg-blue';}
    return respuesta;
  }

  onNewSol(){
    this.onShowForm='formNewSolicitud';
  }

  onEditSol(){
    if (this.selectedSolicitud.idSolicitud!='') {
      this.onShowForm='formEditSolicitud';
    } 
    else{
      alert('Debe seleccionar una solicitud');
    } 
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.ngOnInit();
  }

  onRefresh(){
    this.ngOnInit();
  }

  onFilterState(iState:string){
      if (iState=='TOTAL') {this.criterio$='';}
      else {this.criterio$=iState;}
      this.onSearchCriterio();
  }
}
