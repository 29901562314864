import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GetDataAPIService } from '../../../services/getDataAPI/get-data-api.service';
import { User } from '../../../models/user';
import { globals } from 'src/app/globals';
import *  as  jsAutomovil from '../../../../assets/catalogo/jsAutomovil.json';
import *  as  jsVivienda from '../../../../assets/catalogo/jsVivienda.json';
import *  as  jsEntidades from '../../../../assets/catalogo/jsEntidades.json';
import *  as  jsCanalDesembolsoMP from '../../../../assets/catalogo/jsCanalDersembolsoMP.json';
import *  as  jsTipoGrupoFam from '../../../../assets/catalogo/jsTipoGrupoFam.json';
import *  as  jsSMEnabledButtons from '../../../../assets/catalogo/jsStateMachineEnabledButtonsWorkflow.json';
import *  as  jsLocalidades from '../../../../assets/catalogo/jsLocalidades.json';
import *  as  jsEstadosCiviles from '../../../../assets/catalogo/jsEstadosCiviles.json';
import *  as  jsNivelEducativo from '../../../../assets/catalogo/jsNivelEducativo.json';
import *  as  jsSocioEconomico from '../../../../assets/catalogo/jsSocioEconomico.json';

@Component({
  selector: 'app-analisis-crediticio-edit',
  templateUrl: './analisis-crediticio-edit.component.html',
  styles: []
})
export class AnalisisCrediticioEditComponent implements OnInit {



  @Input() iUserVO$: User;
  @Input() idSolicitud$: String;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string = 'https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';
  urlAPIPromotores: string = 'https://akd7srf6f2.execute-api.us-east-2.amazonaws.com/beta/promotores';
  urlAPIProductos: string = 'https://vgag5qc5i8.execute-api.us-east-2.amazonaws.com/beta/datosestructuraproducto';
  urlAPIOportunidad: string = 'https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes/oportunidades';

  onShowForm: string = '';
  btnGuardar: boolean = false;
  btnRetroceder: boolean = false;
  btnAprobar: boolean = false;
  btnRechazar: boolean = false;
  btnAnular: boolean = false;
  btnSplit: boolean = false;
  onChageValues: boolean = false;


  configButtons: any = {
    id: '',
    form: '',
    boton: '',
    tipoProductos: '',
    estados: '',
    roles: ''
  };

  accionJson: any = {
    idSolicitud: '',
    idTipoProducto: '',
    idEstado: '',
    nickNamePromotorAlta: '',
    nickNameUsuarioModif: '',
    sha256: ''
  };

  ltHijos: any = [{ id: 0, descripcion: '0' }, { id: 1, descripcion: '1' }, { id: 2, descripcion: '2' }, { id: 3, descripcion: '3' }, { id: 4, descripcion: '4' }, { id: 5, descripcion: '5' }, { id: 6, descripcion: '6' }, { id: 7, descripcion: '7' }, { id: 8, descripcion: '8' }, { id: 9, descripcion: '9' }, { id: 10, descripcion: '10' }];
  ltsino: any = [{ id: 'S', descripcion: 'S' }, { id: 'N', descripcion: 'N' }];
  ltEstructuraProducto: any = [];
  ltPromotores: any = [];
  ltAutomovil: any = (jsAutomovil as any).default;
  ltVivienda: any = (jsVivienda as any).default;
  ltEntidades: any = (jsEntidades as any).default;
  ltCanalesDesembolso: any = (jsCanalDesembolsoMP as any).default;
  ltTipoGrupoFam: any = (jsTipoGrupoFam as any).default;
  ltSexo: any = [{ sexo: 'M', descSexo: 'Masculino' }, { sexo: 'F', descSexo: 'Femenino' }, { sexo: 'O', descSexo: 'Otro' }];
  ltLocalidades: any = (jsLocalidades as any).default;
  ltEstadosCiviles: any = (jsEstadosCiviles as any).default;
  ltNivelEducativo: any = (jsNivelEducativo as any).default;
  ltNivelSE: any = (jsSocioEconomico as any).default;
  confSMEnabledButtons: any = (jsSMEnabledButtons as any).default;



  newSolicitud: any = {
    idSolicitud: '',
    idLineaProducto: '',
    descLineaProducto: '',
    idProducto: '',
    descProducto: '',
    idTipoProducto: '',
    descTipoProducto: '',
    idEstado: '',
    descEstado: '',
    idPersonaFyJCliente: '',
    nroTributarioPersonasFyJCliente: '',
    descPersonasFyJCliente: '',
    idActividadEconAfip: '',
    descActividadF883: '',
    fechaNacimiento: '',
    edad: '',
    sexo: '',
    idEstadoCivil: '',
    idNivelEducativo: '',
    idNivelSE: '',
    fechaAlta: '',
    fechaBaja: '',
    nickNamePromotorAlta: '',
    descUsuario: '',
    nickNameUsuarioAlta: '',
    nickNameUsuarioModif: '',
    sha256: '',
    idSolicitudPrestamo: '',
    idEmprendimiento: '',
    descEmprendimiento: '',
    descTipoEmprendimiento: '',
    descEstadoEmprendimiento: '',
    descLugarTrabajoEmprendimiento: '',
    qPersonaFamiliarOcupada: '',
    qPersonaNoFamiliarOcupada: '',
    mpTasa: '',
    mpCuotas: '',
    mpMontoSolicitado: '',
    mpAmortizacion: '',
    mpGarantia01: '',
    mpGarantia02: '',
    mpGaranteSol01IdPFyJ: '',
    mpGaranteSol01DescPersonasFyJ: '',
    mpGaranteSol01NroTributario: '',
    mpGaranteSol02IdPFyJ: '',
    mpGaranteSol02NroTributario: '',
    mpGaranteSol02DescPersonasFyJ: '',
    mpIngresosFamiliares: '',
    mpGastosFamiliares: '',
    mpIngresosEmprendimientos: '',
    mpGastosEmprendimientos: '',
    mpJefasHogar: '',
    mpQHijos: '',
    mpAutomovil: '',
    mpVivienda: '',
    mpEntidad: '',
    mpIdCanalDesembolso: '',
    mpCBU: '',
    rieBcraQSit1: '',
    rieBcraMtoSit1: '',
    rieBcraQChequeRechUlt12M: '',
    rieBcraMtoChequeRechUlt12M: '',
    rieBcraColorChequeRechUlt12M: '',
    rieBcraQEntSitMay1Ult12M: '',
    rieBcraMtoEntSitMay1Ult12M: '',
    rieBcraColorEntSitMay1Ult12M: '',
    rieAnsesMtoPrestamo: '',
    rieFundQMPMora: '',
    rieFundMtoMPMora: '',
    rieColorFundMtoMPMora: '',
    rieFundQMPDeuda: '',
    rieFundMtoMPDeuda: '',
    rieTotDeudaSistema: '',
    rieTotDeudaSistemaColor: '',
    rieUtilidad: '',
    rieValorCuota: '',
    rieApalancamiento: '',
    rieRCIMP: '',
    rieRCIMPColor: '',
    rieGaranteQMoraFund: '',
    rieGaranteMtoMoraFund: '',
    rieGaranteColorMoraFund: '',
    rieResAprobado: '',
    rieResAprobadoColor: '',
    rieResResolucion: '',
    idPersonaFyJTitular: '',
    nroTributarioTitular: '',
    descPersonaFyJTitular: '',
    idTipoGrupoFam: '',
    descTipoGrupoFam: '',
    editable: '',
    abervEstado: '',
    mpTasaPunitorios: '',
    certificado: '',
    certificadoGar01:  '',
    certificadoGar02:  '',
    idDestino:  '',
    splitear:  '',
    abrevGarantia:''
  };

  oportunidad: any = {
    idSolicitud: '',
    idOportunidad: '',
    idSitioReferencia: '',
    idCliente: '',
    opCuotas: '',
    opMontoSolicitado: '',
    opNroTributario: '',
    opCP: '',
    idLocalidad: '',
    opEmail: '',
    opNroCelular: '',
    opDomicilio: '',
    idEstadoOportunidad: '',
    uuid: '',
    fechaRegistro: '',
    ideNroDoc: '',
    ideDenominacion: '',
    ideActividad: '',
    ideQSitUltMes: '',
    idemtoSit1UltMes: '',
    ideQSitMay1UltMes: '',
    ideMtoSitMay1UltMes: '',
    ideMaxSitUlt24Meses: '',
    ideMaxMontoUlt24Meses: '',
    ideQCheques: '',
    ideMtoCheques: '',
    ideImpGanancias: '',
    ideImpIVA: '',
    ideCategoria: '',
    ideIntegranteSoc: '',
    ideEmpleador: '',
    ideActividadMono: '',
    idCliOfertaMP: '',
    cftea: '',
    destino: '',
    grupoDestino: '',
    tna: ''
  };

  selectedProducto: any = {
    idTipoProducto: '',
    descTipoProducto: '',
    idProducto: '',
    descProducto: '',
    idLineaProducto: '',
    descLineaProducto: '',
    descTasa: '',
    tasa: '',
    cuotas: '',
    amortizacion: '',
    emprendedor: '',
    prima: '',
    impuestos: '',
    premio: '',
    activo: '',
    visible: ''
  };

  selectedEmprendimiento: any = {
    idEmprendimiento: '',
    descEstadoEmprendimiento: '',
    descEmprendimiento: '',
    nroTributario: '',
    descPersonasFyJ: '',
    fechaAlta: '',
    fechaUltModificacion: '',
    fechaBaja: ''
  };



  selectedCliente: any = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
  selectedGaranteSol01: any = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
  selectedGaranteSol02: any = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
  selectedTitularMS: any = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
  selectedSolicitud: any = { idSolicitud: '' };


  constructor(private getDataApiService: GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  onClearSelected() {
    this.newSolicitud = {
      idSolicitud: '',
      idLineaProducto: '',
      descLineaProducto: '',
      idProducto: '',
      descProducto: '',
      idTipoProducto: '',
      descTipoProducto: '',
      idEstado: '',
      descEstado: '',
      idPersonaFyJCliente: '',
      nroTributarioPersonasFyJCliente: '',
      descPersonasFyJCliente: '',
      idActividadEconAfip: '',
      descActividadF883: '',
      fechaNacimiento: '',
      edad: '',
      sexo: '',
      idEstadoCivil: '',
      idNivelEducativo: '',
      idNivelSE: '',
      fechaAlta: '',
      fechaBaja: '',
      nickNamePromotorAlta: '',
      descUsuario: '',
      nickNameUsuarioAlta: '',
      nickNameUsuarioModif: '',
      sha256: '',
      idSolicitudPrestamo: '',
      idEmprendimiento: '',
      descEmprendimiento: '',
      descTipoEmprendimiento: '',
      descEstadoEmprendimiento: '',
      descLugarTrabajoEmprendimiento: '',
      qPersonaFamiliarOcupada: '',
      qPersonaNoFamiliarOcupada: '',
      mpTasa: '',
      mpCuotas: '',
      mpMontoSolicitado: '',
      mpAmortizacion: '',
      mpGarantia01: '',
      mpGarantia02: '',
      mpGaranteSol01IdPFyJ: '',
      mpGaranteSol01DescPersonasFyJ: '',
      mpGaranteSol01NroTributario: '',
      mpGaranteSol02IdPFyJ: '',
      mpGaranteSol02NroTributario: '',
      mpGaranteSol02DescPersonasFyJ: '',
      mpIngresosFamiliares: '',
      mpGastosFamiliares: '',
      mpIngresosEmprendimientos: '',
      mpGastosEmprendimientos: '',
      mpJefasHogar: '',
      mpQHijos: '',
      mpAutomovil: '',
      mpVivienda: '',
      mpEntidad: '',
      mpIdCanalDesembolso: '',
      mpCBU: '',
      rieBcraQSit1: '',
      rieBcraMtoSit1: '',
      rieBcraQChequeRechUlt12M: '',
      rieBcraMtoChequeRechUlt12M: '',
      rieBcraColorChequeRechUlt12M: '',
      rieBcraQEntSitMay1Ult12M: '',
      rieBcraMtoEntSitMay1Ult12M: '',
      rieBcraColorEntSitMay1Ult12M: '',
      rieAnsesMtoPrestamo: '',
      rieFundQMPMora: '',
      rieFundMtoMPMora: '',
      rieColorFundMtoMPMora: '',
      rieFundQMPDeuda: '',
      rieFundMtoMPDeuda: '',
      rieTotDeudaSistema: '',
      rieTotDeudaSistemaColor: '',
      rieUtilidad: '',
      rieValorCuota: '',
      rieApalancamiento: '',
      rieRCIMP: '',
      rieRCIMPColor: '',
      rieGaranteQMoraFund: '',
      rieGaranteMtoMoraFund: '',
      rieGaranteColorMoraFund: '',
      rieResAprobado: '',
      rieResAprobadoColor: '',
      rieResResolucion: '',
      idPersonaFyJTitular: '',
      nroTributarioTitular: '',
      descPersonaFyJTitular: '',
      idTipoGrupoFam: '',
      descTipoGrupoFam: '',
      editable: '',
      abervEstado: '',
      mpTasaPunitorios: '',
      certificado: '',
      certificadoGar01:  '',
      certificadoGar02:  '',
      idDestino:  '',
      splitear:  '',
      abrevGarantia:''
    };

    this.oportunidad = {
      idSolicitud: '',
      idOportunidad: '',
      idSitioReferencia: '',
      idCliente: '',
      opCuotas: '',
      opMontoSolicitado: '',
      opNroTributario: '',
      opCP: '',
      idLocalidad: '',
      opEmail: '',
      opNroCelular: '',
      opDomicilio: '',
      idEstadoOportunidad: '',
      uuid: '',
      fechaRegistro: '',
      ideNroDoc: '',
      ideDenominacion: '',
      ideActividad: '',
      ideQSitUltMes: '',
      idemtoSit1UltMes: '',
      ideQSitMay1UltMes: '',
      ideMtoSitMay1UltMes: '',
      ideMaxSitUlt24Meses: '',
      ideMaxMontoUlt24Meses: '',
      ideQCheques: '',
      ideMtoCheques: '',
      ideImpGanancias: '',
      ideImpIVA: '',
      ideCategoria: '',
      ideIntegranteSoc: '',
      ideEmpleador: '',
      ideActividadMono: '',
      idCliOfertaMP: '',
      cftea: '',
      destino: '',
      grupoDestino: '',
      tna:''
    };

    this.selectedProducto = {
      idTipoProducto: '',
      descTipoProducto: '',
      idProducto: '',
      descProducto: '',
      idLineaProducto: '',
      descLineaProducto: '',
      descTasa: '',
      tasa: '',
      tasaPunitorios: '',
      cuotas: '',
      amortizacion: '',
      emprendedor: '',
      prima: '',
      impuestos: '',
      premio: '',
      activo: '',
      visible: ''
    };

    this.selectedEmprendimiento = {
      idEmprendimiento: '',
      descEstadoEmprendimiento: '',
      descEmprendimiento: '',
      nroTributario: '',
      descPersonasFyJ: '',
      fechaAlta: '',
      fechaUltModificacion: '',
      fechaBaja: ''
    };

    this.selectedCliente = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
    this.selectedGaranteSol01 = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
    this.selectedGaranteSol02 = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };
    this.selectedTitularMS = { idPersonasFyJ: '', nroTributario: '', descPersonasFyJ: '', descTipoPersona: '' };

    this.accionJson = {
      idSolicitud: '',
      idEstado: '',
      nickNamePromotorAlta: '',
      nickNameUsuarioModif: '',
      sha256: ''
    };

  }

  ngOnInit() {
    this.onShowForm = 'formAnalisisCrediticioEdit';
    this.getDataProductos();
  }

  getDataProductos() {
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPIProductos)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if ((respuesta['statusCode'] == '200') && (respuesta['body'] != [])) {
          this.ltEstructuraProducto = respuesta['body'];
          this.getDataPromotores();
        }
      })
      .catch((e: any) => {
        // error in e.message
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  getDataPromotores() {
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPIPromotores)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          this.ltPromotores = respuesta['body']['visibles'];
          this.getData();
        }
      })
      .catch((e: any) => {
        // error in e.message
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  onCalcCuota() {
    var tem: number;
    //tem=Math.pow(1+(Number(this.newSolicitud.mpTasa)/100.0),1/12.0)-1;
    tem = (Number(this.newSolicitud.mpTasa) / 100.0) / 12.0;
    //this.newSolicitud.rieValorCuota=Math.round(Number(this.newSolicitud.mpMontoSolicitado)*((tem)/(1-Math.pow((1+Number(tem)),-1*Number(this.newSolicitud.mpCuotas)))));   
    this.newSolicitud.rieValorCuota = Math.round(Number(this.newSolicitud.mpMontoSolicitado) * ((Math.pow((1 + tem), Number(this.newSolicitud.mpCuotas)) * tem) / (Math.pow((1 + tem), Number(this.newSolicitud.mpCuotas)) - 1)));

  }

  onCalcResolucion() {
    var resolucion: boolean;
    resolucion = (this.newSolicitud.rieBcraColorChequeRechUlt12M == 'V') && (this.newSolicitud.rieBcraColorEntSitMay1Ult12M == 'V') && (this.newSolicitud.rieColorFundMtoMPMora == 'V') && (this.newSolicitud.rieRCIMPColor == 'V') && (this.newSolicitud.rieGaranteColorMoraFund == 'V');
    if (resolucion) {
      this.newSolicitud.rieResAprobadoColor = 'V';
      this.newSolicitud.rieResAprobado = 'S';
    }
    else {
      this.newSolicitud.rieResAprobadoColor = 'R';
      this.newSolicitud.rieResAprobado = 'N';
    }
  }

  onCalcApalancamiento() {
    if ((Number(this.newSolicitud.mpIngresosFamiliares) + Number(this.newSolicitud.mpIngresosEmprendimientos)) > 0) {
      this.newSolicitud.rieApalancamiento = Math.round(Number(this.newSolicitud.rieTotDeudaSistema) / (Number(this.newSolicitud.mpIngresosFamiliares) + Number(this.newSolicitud.mpIngresosEmprendimientos)));
    }
    else {
      this.newSolicitud.rieApalancamiento = 0;
    }

  }

  onCalRieValuesInit() {
    this.setColorMPMora();
    this.setColorCheques();
    this.setColorMPMoraGarante();
    this.setColorSitMayor1();
  }

  onCalRieValues() {
    var obsResolucion: string = '';
    this.onChageValues = true;
    this.newSolicitud.rieUtilidad = Number(this.newSolicitud.mpIngresosFamiliares) + Number(this.newSolicitud.mpIngresosEmprendimientos) - Number(this.newSolicitud.mpGastosFamiliares) - Number(this.newSolicitud.mpGastosEmprendimientos);
    this.newSolicitud.rieRCIMP = Math.round(Number(this.newSolicitud.rieUtilidad) * 0.35);
    this.onCalcCuota();
    this.newSolicitud.rieTotDeudaSistema = Number(this.newSolicitud.rieBcraMtoSit1) + Number(this.newSolicitud.rieBcraMtoChequeRechUlt12M) + Number(this.newSolicitud.rieBcraMtoEntSitMay1Ult12M) + Number(this.newSolicitud.rieAnsesMtoPrestamo) + Number(this.newSolicitud.rieFundMtoMPMora) + Number(this.newSolicitud.rieFundMtoMPDeuda);
    this.onCalcApalancamiento();
    if (Number(this.newSolicitud.rieValorCuota) >= Number(this.newSolicitud.rieRCIMP)) {
      this.newSolicitud.rieRCIMPColor = 'R';
    }
    else {
      this.newSolicitud.rieRCIMPColor = 'V';
    }

    this.onCalcResolucion();

    if (this.newSolicitud.rieBcraColorChequeRechUlt12M == 'R') { obsResolucion = obsResolucion + 'Posee cheques rechazadon en BCRA. '; }
    if (this.newSolicitud.rieBcraColorEntSitMay1Ult12M == 'R') { obsResolucion = obsResolucion + 'Tiene deuda situacion > 1 en entidades financieras. '; }
    if (this.newSolicitud.rieColorFundMtoMPMora == 'R') { obsResolucion = obsResolucion + 'Tiene obligaciones impagas con la institución.  '; }
    if (this.newSolicitud.rieRCIMPColor == 'R') { obsResolucion = obsResolucion + 'Supera la relación cuota ingreso. '; }
    if (this.newSolicitud.rieGaranteColorMoraFund == 'R') { obsResolucion = obsResolucion + 'Uno o mas garantes poseen deuda con la institución. '; }
    this.newSolicitud.rieResResolucion = obsResolucion;
  }

  getData() {
    var respuesta: any;
    this.selectedSolicitud.idSolicitud = this.idSolicitud$;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI + '/solicitud', this.selectedSolicitud)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          this.newSolicitud = respuesta['body'][0];
          this.selectedProducto = this.ltEstructuraProducto.filter(element => (element.idLineaProducto == respuesta['body'][0].idLineaProducto))[0];
          this.selectedCliente.idPersonasFyJ = respuesta['body'][0].idPersonaFyJCliente;
          this.selectedCliente.nroTributario = respuesta['body'][0].nroTributarioPersonasFyJCliente;
          this.selectedCliente.descPersonasFyJ = respuesta['body'][0].descPersonasFyJCliente;
          this.selectedEmprendimiento.idEmprendimiento = respuesta['body'][0].idEmprendimiento;
          this.selectedEmprendimiento.descEmprendimiento = respuesta['body'][0].descEmprendimiento;

          this.selectedGaranteSol01.idPersonasFyJ = respuesta['body'][0].mpGaranteSol01IdPFyJ;
          this.selectedGaranteSol01.nroTributario = respuesta['body'][0].mpGaranteSol01NroTributario;
          this.selectedGaranteSol01.descPersonasFyJ = respuesta['body'][0].mpGaranteSol01DescPersonasFyJ;
          this.selectedGaranteSol02.idPersonasFyJ = respuesta['body'][0].mpGaranteSol02IdPFyJ;
          this.selectedGaranteSol02.nroTributario = respuesta['body'][0].mpGaranteSol02NroTributario;
          this.selectedGaranteSol02.descPersonasFyJ = respuesta['body'][0].mpGaranteSol02DescPersonasFyJ;


          this.newSolicitud.nickNameUsuarioModif = this.iUserVO$.user;
          if (this.newSolicitud.idEstado == 16) { //PENDIENTE ANALISIS CREDITO
            this.onCalRieValuesInit();
            this.onChageValues = true;
          }
          else {
            this.onCalcCuota();
            this.onChageValues = false;
          }
          this.onSetEditButtons();
          this.getDataOPortunidad();
        }
      })
      .catch((e: any) => {
        // error in e.message
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  getDataOPortunidad() {
    var respuesta: any;
    var soliData: any = { idSolicitud: '', idTenant: '' };
    soliData.idSolicitud = this.newSolicitud.idSolicitud;
    soliData.idTenant = this.iUserVO$.idTenant
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPIOportunidad, soliData)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if ((respuesta['statusCode'] == '200') && (respuesta['body'] != [])) {
          this.oportunidad = respuesta['body'];
        }
      })
      .catch((e: any) => {
        // error in e.message
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });

  }

  listenAssignEventProduct(iProducto: any) {
    this.selectedProducto = iProducto;
    this.newSolicitud.idLineaProducto = this.selectedProducto.idLineaProducto;
    this.newSolicitud.idTipoProducto = this.selectedProducto.idTipoProducto;
    this.newSolicitud.idProducto = this.selectedProducto.idProducto;
    if (this.selectedProducto.idTipoProducto == 1) {
      this.newSolicitud.mpTasa = this.selectedProducto.tasa;
      this.newSolicitud.mpTasaPunitorios = this.selectedProducto.tasaPunitorios;
      this.newSolicitud.mpAmortizacion = this.selectedProducto.amortizacion;
      this.newSolicitud.mpCuotas='';
      this.onCalcCuota();
    }
  }

  listenAssignEventCliente(iCliente: any) {
    this.selectedCliente = iCliente;
    this.newSolicitud.idPersonaFyJCliente = this.selectedCliente.idPersonasFyJ;
  }


  listenAssignEventGaranteSol01(iGarante: any) {
    this.selectedGaranteSol01 = iGarante;
    this.newSolicitud.mpGaranteSol01IdPFyJ = this.selectedGaranteSol01.idPersonasFyJ;
  }

  listenAssignEventGaranteSol02(iGarante: any) {
    this.selectedGaranteSol02 = iGarante;
    this.newSolicitud.mpGaranteSol02IdPFyJ = this.selectedGaranteSol02.idPersonasFyJ;
  }

  listenAssignEventEmprendimiento(iEmprendimiento: any) {
    this.selectedEmprendimiento = iEmprendimiento;
    this.newSolicitud.idEmprendimiento = this.selectedEmprendimiento.idEmprendimiento;
  }

  listenAssignEventTitularMS(iTitular: any) {
    this.selectedTitularMS = iTitular;
    this.newSolicitud.msTitular = this.selectedTitularMS.idPersonasFyJ;
  }

  onExit() {
    //console.log(JSON.stringify(this.newPersonaFisica));
    this.closeEvent.emit(true);
  }

  onSetFormShow(iForm: any) {
    if (iForm == 'formEmprendimientoSearch') {
      if (this.selectedCliente.idPersonasFyJ != '') {
        this.onShowForm = iForm;
      }
      else {
        alert('Seleccione un Cliente');
      }
    }
    else {
      this.onShowForm = iForm;
    }

  }

  listenCloseEvent(iMenuItemSelected: any) {
    this.onShowForm = 'formAnalisisCrediticioEdit';
  }

  onChageGarantia01(iGarantia: String) {
    this.newSolicitud.mpGarantia01 = iGarantia;
  }

  onChageGarantia02(iGarantia: String) {
    this.newSolicitud.mpGarantia02 = iGarantia;
  }

  onCodigoError(): string {
    var verifica, v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13, v14: Boolean;
    if (this.newSolicitud.idTipoProducto == 1) {
      v1 = (String(this.newSolicitud.idLineaProducto) != '' && String(this.newSolicitud.idLineaProducto) != '45');
      v2 = (String(this.newSolicitud.mpMontoSolicitado) != '' && this.isNumeric(String(this.newSolicitud.mpMontoSolicitado)));
      v3 = (String(this.newSolicitud.mpCuotas).length >= 1);

      v4 = ((String(this.newSolicitud.rieBcraQSit1).length >= 1) && this.isNumeric(String(this.newSolicitud.rieBcraQSit1)));
      v5 = ((String(this.newSolicitud.rieBcraMtoSit1).length >= 1) && this.isNumeric(String(this.newSolicitud.rieBcraMtoSit1)));
      v6 = ((String(this.newSolicitud.rieBcraQChequeRechUlt12M).length >= 1) && this.isNumeric(String(this.newSolicitud.rieBcraQChequeRechUlt12M)));
      v7 = ((String(this.newSolicitud.rieBcraMtoChequeRechUlt12M).length >= 1) && this.isNumeric(String(this.newSolicitud.rieBcraMtoChequeRechUlt12M)));

      v8 = ((String(this.newSolicitud.rieBcraQEntSitMay1Ult12M).length >= 1) && this.isNumeric(String(this.newSolicitud.rieBcraQEntSitMay1Ult12M)));
      v9 = ((String(this.newSolicitud.rieBcraMtoEntSitMay1Ult12M).length >= 1) && this.isNumeric(String(this.newSolicitud.rieBcraMtoEntSitMay1Ult12M)));
      v10 = ((String(this.newSolicitud.rieAnsesMtoPrestamo).length >= 1) && this.isNumeric(String(this.newSolicitud.rieAnsesMtoPrestamo)));
      v11 = ((String(this.newSolicitud.rieFundQMPMora).length >= 1) && this.isNumeric(String(this.newSolicitud.rieFundQMPMora)));
      v12 = ((String(this.newSolicitud.rieFundMtoMPMora).length >= 1) && this.isNumeric(String(this.newSolicitud.rieFundMtoMPMora)));
      v13 = ((String(this.newSolicitud.rieFundQMPDeuda).length >= 1) && this.isNumeric(String(this.newSolicitud.rieFundQMPDeuda)));
      v14 = ((String(this.newSolicitud.rieFundMtoMPDeuda).length >= 1) && this.isNumeric(String(this.newSolicitud.rieFundMtoMPDeuda)));
     
      verifica = String(Number(v1)) + String(Number(v2)) + String(Number(v3)) + String(Number(v4)) + String(Number(v5)) + String(Number(v6)) + String(Number(v7)) + String(Number(v8)) + String(Number(v9)) + String(Number(v10)) + String(Number(v11)) + String(Number(v12)) + String(Number(v13)) + String(Number(v14));
    }

    return verifica;
  }

  onLeyendaError(errorCode: string): string {
    var leyenda: string;
    leyenda = 'ERROR - NO SE PUEDE ACTUALIZAR LA SOLICITUD. \nVERIFIQUE LOS SIGUIENTES CAMPOS: \n';
    if (this.newSolicitud.idTipoProducto == 1) {
      if (errorCode.substr(0, 1) == '0') { leyenda = leyenda + 'Producto, debe asignar una linea\n' }
      if (errorCode.substr(1, 1) == '0') { leyenda = leyenda + 'Monto \n' }
      if (errorCode.substr(2, 1) == '0') { leyenda = leyenda + 'Cuotas \n' }
      if (errorCode.substr(3, 1) == '0') { leyenda = leyenda + 'Q Situación 1 \n' }
      if (errorCode.substr(4, 1) == '0') { leyenda = leyenda + 'Monto Situación 1 \n' }
      if (errorCode.substr(5, 1) == '0') { leyenda = leyenda + 'Q Entidades con Situación Mayor a 1 \n' }
      if (errorCode.substr(6, 1) == '0') { leyenda = leyenda + 'Monto Entidades con Situación Mayor a 1 \n' }
      if (errorCode.substr(7, 1) == '0') { leyenda = leyenda + 'Q Cheques Rechazados \n' }
      if (errorCode.substr(8, 1) == '0') { leyenda = leyenda + 'Monto Cheques Rechazados \n' }
      if (errorCode.substr(9, 1) == '0') { leyenda = leyenda + 'Monto Prestamo ANSES \n' }
      if (errorCode.substr(10, 1) == '0') { leyenda = leyenda + 'Q Prestamos en Mora \n' }
      if (errorCode.substr(11, 1) == '0') { leyenda = leyenda + 'Monto Prestamos en Mora \n' }
      if (errorCode.substr(12, 1) == '0') { leyenda = leyenda + 'Q Prestamos Sin Deuda \n' }
      if (errorCode.substr(13, 1) == '0') { leyenda = leyenda + 'Monto Prestamos Sin Deuda \n' }
    }

    return leyenda;
  }

  isNumeric(k: string): Boolean {
    var r: Boolean = k.length > 0;
    for (var i = 0; i < k.length; i++) { r = r && RegExp('[0-9]').test(k[i]) }
    return r;
  }

  validarLargoCBU(numero: string): Boolean {
    if (numero.length != 22) { return false; }
    return true;
  }

  validarBloque1(bloque1: string): Boolean {
    if (bloque1.length != 8) { return false; }
    var banco = bloque1.substr(0, 3);
    var digitoVerificador1 = bloque1[3];
    var sucursal = bloque1.substr(4, 3);
    var digitoVerificador2 = bloque1[7];
    var suma = Number(banco[0]) * 7 + Number(banco[1]) * 1 + Number(banco[2]) * 3 + Number(digitoVerificador1) * 9 + Number(sucursal[0]) * 7 + Number(sucursal[1]) * 1 + Number(sucursal[2]) * 3;
    var diferencia = 10 - (suma % 10);
    return (Number(diferencia) == Number(digitoVerificador2));
  }

  validarBloque2(bloque2: string): Boolean {
    if (bloque2.length != 14) { return false; }
    var digitoVerificador = bloque2[13];
    var suma = Number(bloque2[0]) * 3 + Number(bloque2[1]) * 9 + Number(bloque2[2]) * 7 + Number(bloque2[3]) * 1 + Number(bloque2[4]) * 3 + Number(bloque2[5]) * 9 + Number(bloque2[6]) * 7 + Number(bloque2[7]) * 1 + Number(bloque2[8]) * 3 + Number(bloque2[9]) * 9 + Number(bloque2[10]) * 7 + Number(bloque2[11]) * 1 + Number(bloque2[12]) * 3;
    var diferencia = 10 - (suma % 10);
    return (Number(diferencia) == Number(digitoVerificador));
  }

  validarCBU(nrocbu: string) {
    return this.validarLargoCBU(nrocbu) && this.validarBloque1(nrocbu.substr(0, 8)) && this.validarBloque2(nrocbu.substr(8, 14));
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onGuardar() {
    if (this.onCodigoError() == '11111111111111') {
      var respuesta: any;
      this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI + '/riesgo', this.newSolicitud)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta = JSON.parse(data);
          if (respuesta['statusCode'] == '200') {
            alert('La Solicitud se actualizó exitosamente');
            this.onChageValues = false;
          }
          if (respuesta['statusCode'] == '400') {
            alert('ERROR - No se puede actualizar la solicitud');
          }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    }
    else {
      alert(this.onLeyendaError(this.onCodigoError()));
    }
  }

  onSetEditButtons() {

    this.configButtons = this.confSMEnabledButtons.find(element => element.id === "BTN_001_FRM_002");
    this.btnGuardar = (this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase()) !== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto) !== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado) !== -1);

    this.configButtons = this.confSMEnabledButtons.find(element => element.id === "BTN_002_FRM_002");
    this.btnRetroceder = (this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase()) !== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto) !== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado) !== -1);

    this.configButtons = this.confSMEnabledButtons.find(element => element.id === "BTN_003_FRM_002");
    this.btnAprobar = (this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase()) !== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto) !== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado) !== -1);

    this.configButtons = this.confSMEnabledButtons.find(element => element.id === "BTN_004_FRM_002");
    this.btnRechazar = (this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase()) !== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto) !== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado) !== -1);

    this.configButtons = this.confSMEnabledButtons.find(element => element.id === "BTN_005_FRM_002");
    this.btnAnular = (this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase()) !== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto) !== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado) !== -1);

    this.configButtons = this.confSMEnabledButtons.find(element => element.id === "BTN_006_FRM_002");
    this.btnSplit = (this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase()) !== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto) !== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado) !== -1);

  }


  onRetroceder() {
    var respuesta: any;
    if (this.onChageValues) {
      alert('Los parametros de la solicitud cambiaron. Por favor guarde los cambios y luego proceda con la acción');
    }
    else {
      this.configButtons = this.confSMEnabledButtons.find(element => element.id === 'BTN_002_FRM_002');
      this.onSetNewState(this.configButtons.nextState);
    }
  }

  onAprobar() {
    var respuesta: any;
    if (this.onChageValues) {
      alert('Los parametros de la solicitud cambiaron. Por favor guarde los cambios y luego proceda con la acción');
    }
    else {
      this.configButtons = this.confSMEnabledButtons.find(element => element.id === 'BTN_003_FRM_002');
      this.onSetNewState(this.configButtons.nextState);
    }
  }

  onRechazar() {
    var respuesta: any;
    if (this.onChageValues) {
      alert('Los parametros de la solicitud cambiaron. Por favor guarde los cambios y luego proceda con la acción');
    }
    else {
      this.configButtons = this.confSMEnabledButtons.find(element => element.id === 'BTN_004_FRM_002');
      this.onSetNewState(this.configButtons.nextState);
    }
  }


  onAnular() {
    var respuesta: any;
    if (this.onChageValues) {
      alert('Los parametros de la solicitud cambiaron. Por favor guarde los cambios y luego proceda con la acción');
    }
    else {
      this.configButtons = this.confSMEnabledButtons.find(element => element.id === 'BTN_005_FRM_002');
      this.onSetNewState(this.configButtons.nextState);
    }
  }

  onSetNewState(iNewState: number) {
    var respuesta: any;
    this.accionJson.idSolicitud = this.newSolicitud.idSolicitud;
    this.accionJson.idTipoProducto = this.newSolicitud.idTipoProducto;
    this.accionJson.idEstado = iNewState;
    this.accionJson.nickNamePromotorAlta = this.newSolicitud.nickNamePromotorAlta;
    this.accionJson.nickNameUsuarioModif = this.iUserVO$.user;
    this.accionJson.sha256 = this.newSolicitud.sha256;
    this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI + '/estado', this.accionJson)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          alert('La operación se realizó exitosamente');
          this.onExit();
        }
        if (respuesta['statusCode'] == '400') {
          alert('ERROR - No se puede efectuar la operación');
        }
      })
      .catch((e: any) => {
        // error in e.message
        //alert('Error: ' + e.message);
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  getColorSemaforo(iColor: string) {
    if (iColor.toUpperCase() == 'V') { return 'label label-success pull-left'; }
    if (iColor.toUpperCase() == 'A') { return 'label label-warning pull-left'; }
    if (iColor.toUpperCase() == 'R') { return 'label label-danger pull-left'; }
  }

  getNombreColorSemaforo(iColor: string): string {
    if (iColor.toUpperCase() == 'V') { return 'VERDE'; }
    if (iColor.toUpperCase() == 'A') { return 'AMARILLO'; }
    if (iColor.toUpperCase() == 'R') { return 'ROJO'; }
  }

  getNombreColorResolucion(i: string): string {
    if (i.toUpperCase() == 'S') { return 'APROBADA'; }
    if (i.toUpperCase() == 'N') { return 'RECHAZADA'; }
  }


  setColorMPMora() {
    if (this.newSolicitud.rieFundMtoMPMora > 0) {
      this.newSolicitud.rieColorFundMtoMPMora = 'R';
      if (this.newSolicitud.rieFundQMPMora == 0) {
        this.newSolicitud.rieFundQMPMora = 1;
      }
    }
    if (this.newSolicitud.rieFundMtoMPMora == 0) {
      this.newSolicitud.rieColorFundMtoMPMora = 'V';
      this.newSolicitud.rieFundQMPMora = 0;
    }
    this.onCalRieValues();
  }

  setColorMPMoraGarante() {
    if (this.newSolicitud.rieGaranteMtoMoraFund > 0) {
      this.newSolicitud.rieGaranteColorMoraFund = 'R';
      if (this.newSolicitud.rieGaranteQMoraFund == 0) {
        this.newSolicitud.rieGaranteQMoraFund = 1;
      }
    }
    if (this.newSolicitud.rieGaranteMtoMoraFund == 0) {
      this.newSolicitud.rieGaranteColorMoraFund = 'V';
      this.newSolicitud.rieGaranteQMoraFund = 0;
    }
    this.onCalRieValues();
  }


  setSit1() {
    if (this.newSolicitud.rieBcraMtoSit1 > 0) {
      if (this.newSolicitud.rieBcraQSit1 == 0) {
        this.newSolicitud.rieBcraQSit1 = 1;
      }
    }
    this.onCalRieValues();
  }


  setColorSitMayor1() {
    if (this.newSolicitud.rieBcraMtoEntSitMay1Ult12M > 0) {
      this.newSolicitud.rieBcraColorEntSitMay1Ult12M = 'R';
      if (this.newSolicitud.rieBcraQEntSitMay1Ult12M == 0) {
        this.newSolicitud.rieBcraQEntSitMay1Ult12M = 1;
      }
    }
    if (this.newSolicitud.rieBcraMtoEntSitMay1Ult12M == 0) {
      this.newSolicitud.rieBcraColorEntSitMay1Ult12M = 'V';
    }
    this.onCalRieValues();
  }


  setColorCheques() {
    if (this.newSolicitud.rieBcraMtoChequeRechUlt12M > 0) {
      this.newSolicitud.rieBcraColorChequeRechUlt12M = 'R';
      if (this.newSolicitud.rieBcraQChequeRechUlt12M == 0) {
        this.newSolicitud.rieBcraQChequeRechUlt12M = 1;
      }
    }
    if (this.newSolicitud.rieBcraMtoChequeRechUlt12M == 0) {
      this.newSolicitud.rieBcraColorChequeRechUlt12M = 'V';
    }
    this.onCalRieValues();
  }

  setPtmoFund() {
    if (this.newSolicitud.rieFundMtoMPDeuda > 0) {
      if (this.newSolicitud.rieFundQMPDeuda == 0) {
        this.newSolicitud.rieFundQMPDeuda = 1;
      }
    }
    this.onCalRieValues();
  }


  onChangeResolucion() {
    this.onChageValues = true;
  }

  setState(iState: String): String {
    var respuesta: String;
    if (iState == 'PRECARGA') { respuesta = 'label label-default pull-left'; }
    if (iState == 'VIGENTE') { respuesta = 'label label-success pull-left'; }
    if (iState == 'BAJA') { respuesta = 'label label-info pull-left'; }
    if (iState == 'ANULADA') { respuesta = 'label label-info pull-left'; }
    if (iState == 'APROBADA') { respuesta = 'label label-primary pull-left'; }
    if (iState == 'CANCELADA') { respuesta = 'label label-primary pull-left'; }
    if (iState == 'RECHAZADA') { respuesta = 'label label-danger pull-left'; }
    if (iState == 'PENDIENTE') { respuesta = 'label label-warning pull-left'; }
    if (iState == 'MORA') { respuesta = 'label label-danger pull-left'; }
    if (iState == 'DESEMBOLSADA') { respuesta = 'label label-success pull-left'; }
    return respuesta;
  }

  onSplit(){
    if (this.newSolicitud.splitear){
      this.onSetFormShow('formSplit');
    }
    else{
      alert('No se puede dividir una solocitud que ya fue dividida. Elija la original');
    }
  }
  
}
