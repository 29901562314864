export class User {
    jwtToken:string='';
    user: string;
    pwd: string;
    apellidoNombre:string='';
    descRol: string='';
    isLogged: boolean = false;
    idTenant: string='';
    institucion: string='';
    picture:string='';
}
