import { Component, OnInit, Input,EventEmitter, Output } from '@angular/core';
import {MenuItem} from '../../../models/menu-item';
import {User} from '../../../models/user';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit {


  @Input() menuItemSelected$: MenuItem;

  @Input() iUserVO$: User;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit() {
   
  }

  listenCloseEvent(iMenuItemSelected: MenuItem){
    this.closeEvent.emit(true);
  }

}
