import { Injectable } from '@angular/core';
import {AuthenticationDetails, CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import {Observable} from 'rxjs';


const poolData = {
  UserPoolId: 'us-east-2_5RVB450ZQ',//'us-east-2_NqYreRTeJ', // Id de grupo - Your user pool id here
  ClientId: '2aq2m4svo6q386ea3bfgtst361'//'7j9j57mndncso34qfi2i2oejgv' // Your client id here  
};

const userPool = new CognitoUserPool(poolData);

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  cognitoUser: any;

  constructor() { }

  signIn(email: string, password: string) { 

    const authenticationData = {
      Username : email,
      Password : password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username : email,
      Pool : userPool
    };

    const cognitoUser = new CognitoUser(userData);
    
    return Observable.create(observer => {

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          observer.next(result);
          observer.complete();
        },
        onFailure: function(err) {
          observer.error(err);
        },
        newPasswordRequired: function(userAttributes, requiredAttributes) {
          cognitoUser.completeNewPasswordChallenge(password,null, this);
        }
      });
    });
  }

  isLoggedIn() {    
    return userPool.getCurrentUser() != null;
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return userPool.getCurrentUser();
  }

  logOut() {
    this.getAuthenticatedUser().signOut();
    this.cognitoUser = null;
  }
}
