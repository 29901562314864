import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';

@Component({
  selector: 'app-prod-cliente-detalle',
  templateUrl: './prod-cliente-detalle.component.html'
})
export class ProdClienteDetalleComponent implements OnInit {

  
  @Input() iUserVO$: User;
  @Input() selectedSolicitud: any;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string='https://4ll0n8p6sl.execute-api.us-east-2.amazonaws.com/beta/dataproductos';

  public cuotas:any;
  public btnLiquidar:boolean=true;

  public operacion: any={
      idSolicitudPrestamo: '',
      nroOperacionTransf:''
  }

  public liquidacion: any = { idProdPrestamoLiq: '',
                              idSolicitudPrestamo: '',
                              tasaLiq: '',
                              cuotasLiq: '',
                              montoLiq: '',
                              costoSaldoDeudor: '',
                              costoAmparo: '',
                              prima: '',
                              gastoAdministrativo: '',
                              tasaSuperIntendencia: '',
                              subTotalSeguros: '',
                              administracionFundacion: '',
                              totalSeguroCuota: '',
                              totalSegurosLiquidacion: '',
                              socioAdherente: '',
                              retencionDesembolso: '',
                              totalDesembolso: '',
                              tem: '',
                              valorCuotaLiq: '',
                              fechaDesembolso: '',
                              idCanalDesembolso: '',
                              descCanalDesembolso: '',
                              cbu: '',
                              nroOperacionTransf: '',
                              idEstado: '',
                              cuotasLiquidadas: '',
                              idSolicitudSegMed: '',
                              idSolicitud: '',
                              fechaIniVigencia: '',
                              fechaFinVigencia: '',
                              vigente: '',
                              descEstado: '',
                              idPersonaFyJTitular: '',
                              nroTributarioTitular: '',
                              descPersonaFyJTitular: '',
                              idTipoGrupoFam: '',
                              descTipoGrupoFam: ''};

  constructor(private getDataApiService:GetDataAPIService,@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.onGetData();
  }

  setState(iState:String):String{
    var respuesta: String;
    if(iState=='N'){respuesta='label label-danger pull-right';}
    if(iState=='S'){respuesta='label label-success pull-right';}
    return respuesta;
  }

  setStateCuota(iState:String):String{
    var respuesta: String;
    if(iState=='TIEMPO Y FORMA'){respuesta='label label-success pull-right';}
    if(iState=='ADEUDADA'){respuesta='label label-warning pull-right';}
    if(iState=='ATRASADA'){respuesta='label label-danger pull-right';}
    if(iState=='ANTICIPADA'){respuesta='label label-success pull-right';}
    if(iState=='REFINANCIADA'){respuesta='label label-info pull-right';}
    if(iState=='REPROGRAMADA'){respuesta='label label-primary pull-right';}
    if(iState=='FONDO GARANTIA'){respuesta='label label-default pull-right';}
    if(iState=='FUERA TERMINO'){respuesta='label label-warning pull-right';}
    return respuesta;
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onGetData(){
        var respuesta: any;
        this.selectedSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
        this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI +'/solicitud',this.selectedSolicitud)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta=JSON.parse(data);
          if (respuesta['statusCode']=='200'){   
            this.liquidacion=respuesta['body'];
            this.btnLiquidar=false;
          }
          if (respuesta['statusCode']=='400'){   
            alert('ERROR - No se puede obtener los datos del producto');
          }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
  }

  
  onExit(){
    this.closeEvent.emit(true);
  }

  setStateProducto(iState:String):String{
    var respuesta: String;
    if(iState=='VIGENTE'){respuesta='label label-success pull-right';}
    if(iState=='BAJA'){respuesta='label label-info pull-right';}
    if(iState=='CANCELADA'){respuesta='label label-warning pull-right';}
    if(iState=='MORA'){respuesta='label label-danger pull-right';}
    if(iState=='INCOBRABLE'){respuesta='label label-danger pull-right';}
    if(iState=='RECUPERO'){respuesta='label label-warning pull-right';}
    return respuesta;
  }

  

}
