import { Component, OnInit,Output,Input,EventEmitter,Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import {User} from '../../../models/user';
import { globals } from 'src/app/globals';
import  *  as  jsAutomovil from  '../../../../assets/catalogo/jsAutomovil.json';
import  *  as  jsVivienda from  '../../../../assets/catalogo/jsVivienda.json';
import  *  as  jsEntidades from  '../../../../assets/catalogo/jsEntidades.json';
import  *  as  jsCanalDesembolsoMP from  '../../../../assets/catalogo/jsCanalDersembolsoMP.json';
import  *  as  jsTipoGrupoFam from  '../../../../assets/catalogo/jsTipoGrupoFam.json';
import  *  as  jsSMEnabledButtons from  '../../../../assets/catalogo/jsStateMachineEnabledButtonsWorkflow.json';

@Component({
  selector: 'app-sol-solicitudes-edit',
  templateUrl: './sol-solicitudes-edit.component.html',
  styles: []
})
export class SolSolicitudesEditComponent implements OnInit {

  
  
  @Input() iUserVO$: User;
  @Input() idSolicitud$: String;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  

  urlAPI: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';
  urlAPIPromotores: string='https://akd7srf6f2.execute-api.us-east-2.amazonaws.com/beta/promotores';
  urlAPIProductos: string='https://vgag5qc5i8.execute-api.us-east-2.amazonaws.com/beta/datosestructuraproducto';
  urlAPIOportunidad: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes/oportunidades';
  

  onShowForm: string='';
  btnGuardar:boolean=false;
  btnAvanzar:boolean=false;
  btnAnular:boolean=false;
  btnPrecarga:boolean=false;
  btnFirma:boolean=false;
  onChageValues:boolean=false;

  configButtons: any = {
    id: '',
    form: '',
    boton: '',
    tipoProductos: '',
    estados: '',
    roles: ''
  };

  avanzarJson: any = {
    idSolicitud: '',
    idTipoProducto:'',
    idEstado: '',
    nickNamePromotorAlta: '',
    nickNameUsuarioModif: '',
    sha256: '',
    msValorCuota:''
  };

  ltHijos:any=[{id:0,descripcion:'0'},{id:1,descripcion:'1'},{id:2,descripcion:'2'},{id:3,descripcion:'3'},{id:4,descripcion:'4'},{id:5,descripcion:'5'},{id:6,descripcion:'6'},{id:7,descripcion:'7'},{id:8,descripcion:'8'},{id:9,descripcion:'9'},{id:10,descripcion:'10'}];
  ltsino:any=[{id:'S',descripcion:'S'},{id:'N',descripcion:'N'}];
  ltEstructuraProducto: any = [];
  ltPromotores: any = [];
  ltAutomovil: any = (jsAutomovil as any).default;
  ltVivienda: any = (jsVivienda as any).default;
  ltEntidades: any = (jsEntidades as any).default;
  ltCanalesDesembolso: any = (jsCanalDesembolsoMP as any).default;
  ltTipoGrupoFam: any = (jsTipoGrupoFam as any).default;
  confSMEnabledButtons: any = (jsSMEnabledButtons as any).default;
  


  newSolicitud: any= {idSolicitud:'',
                      idLineaProducto:'',
                      descLineaProducto:'',
                      idProducto:'',
                      descProducto:'',
                      idTipoProducto:'',
                      descTipoProducto:'',
                      idEstado:'',
                      descEstado:'',
                      idPersonaFyJCliente:'',
                      nroTributarioPersonasFyJCliente:'',
                      descPersonasFyJCliente:'',
                      idActividadEconAfip:'',
                      descActividadF883:'',
                      fechaNacimiento:'',
                      edad:'',
                      sexo:'',
                      idEstadoCivil:'',
                      idNivelEducativo:'',
                      idNivelSE:'',
                      fechaAlta:'',
                      fechaBaja:'',
                      nickNamePromotorAlta:'',
                      descUsuario:'',
                      nickNameUsuarioAlta:'',
                      nickNameUsuarioModif:'',
                      sha256:'',
                      idSolicitudPrestamo:'',
                      idEmprendimiento:'',
                      descEmprendimiento:'',
                      descTipoEmprendimiento: '',
                      descEstadoEmprendimiento: '',
                      descLugarTrabajoEmprendimiento: '',
                      qPersonaFamiliarOcupada: '',
                      qPersonaNoFamiliarOcupada: '',
                      mpTasa:'',
                      mpCuotas:'',
                      mpValorCuota:'',
                      mpMontoSolicitado:'',
                      mpAmortizacion:'',
                      mpGarantia01:'',
                      mpGarantia02:'',
                      mpGaranteSol01IdPFyJ:'',
                      mpGaranteSol01DescPersonasFyJ:'',
                      mpGaranteSol01NroTributario:'',
                      mpGaranteSol02IdPFyJ:'',
                      mpGaranteSol02NroTributario:'',
                      mpGaranteSol02DescPersonasFyJ:'',
                      mpIngresosFamiliares:'',
                      mpGastosFamiliares:'',
                      mpIngresosEmprendimientos:'',
                      mpGastosEmprendimientos:'',
                      mpJefasHogar:'',
                      mpQHijos:'',
                      mpAutomovil:'',
                      mpVivienda:'',
                      mpEntidad:'',
                      mpIdCanalDesembolso:'',
                      mpCBU:'',
                      rieBcraQSit1:'',
                      rieBcraMtoSit1:'',
                      rieBcraQChequeRechUlt12M:'',
                      rieBcraMtoChequeRechUlt12M:'',
                      rieBcraColorChequeRechUlt12M:'',
                      rieBcraQEntSitMay1Ult12M:'',
                      rieBcraMtoEntSitMay1Ult12M:'',
                      rieBcraColorEntSitMay1Ult12M:'',
                      rieAnsesMtoPrestamo:'',
                      rieFundQMPMora:'',
                      rieFundMtoMPMora:'',
                      rieColorFundMtoMPMora:'',
                      rieFundQMPDeuda:'',
                      rieFundMtoMPDeuda:'',
                      rieTotDeudaSistema:'',
                      rieTotDeudaSistemaColor:'',
                      rieRCIMP:'',
                      rieRCIMPColor:'',
                      rieGaranteQMoraFund: '',
                      rieGaranteMtoMoraFund: '',
                      rieGaranteColorMoraFund: '',
                      rieResAprobado:'',
                      rieResAprobadoColor:'',
                      rieResResolucion:'',
                      idPersonaFyJTitular:'',
                      nroTributarioTitular:'',
                      descPersonaFyJTitular:'',
                      idTipoGrupoFam:'',
                      descTipoGrupoFam:'',
                      editable:'',
                      abervEstado:'',
                      mpTasaPunitorios:'',
                      certificado:'',
                      certificadoGar01:'',
                      certificadoGar02:'',
                      idDestino:'',
                      abrevGarantia:''
                    };

  oportunidad: any= {idSolicitud: '',
                      idOportunidad: '',
                      idSitioReferencia: '',
                      idCliente: '',
                      opCuotas: '',
                      opMontoSolicitado: '',
                      opNroTributario: '',
                      opCP: '',
                      idLocalidad: '',
                      opEmail: '',
                      opNroCelular: '',
                      opDomicilio: '',
                      idEstadoOportunidad: '',
                      uuid: '',
                      fechaRegistro: '',
                      ideNroDoc: '',
                      ideDenominacion: '',
                      ideActividad: '',
                      ideQSitUltMes: '',
                      idemtoSit1UltMes: '',
                      ideQSitMay1UltMes: '',
                      ideMtoSitMay1UltMes: '',
                      ideMaxSitUlt24Meses: '',
                      ideMaxMontoUlt24Meses: '',
                      ideQCheques: '',
                      ideMtoCheques: '',
                      ideImpGanancias: '',
                      ideImpIVA: '',
                      ideCategoria: '',
                      ideIntegranteSoc: '',
                      ideEmpleador: '',
                      ideActividadMono: '',
                      idCliOfertaMP: '',
                      cftea: '',
                      destino: '',
                      grupoDestino: '',
                      tna:''};

  selectedProducto: any={idTipoProducto : '',
                      descTipoProducto : '',
                      idProducto : '',
                      descProducto : '',
                      idLineaProducto : '',
                      descLineaProducto : '',
                      descTasa: '',
                      tasa : '',
                      tasaPunitorios:'',
                      cuotas : '',
                      amortizacion : '',
                      emprendedor : '',
                      prima:'',
                      impuestos: '',
                      premio: '',
                      activo : '',
                      visible : ''};

  selectedEmprendimiento: any={ idEmprendimiento: '',
                      descEstadoEmprendimiento: '',
                      descEmprendimiento: '',
                      nroTributario: '',
                      descPersonasFyJ: '',
                      fechaAlta: '',
                      fechaUltModificacion: '',
                      fechaBaja: ''};

                    

  selectedCliente: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
  selectedGaranteSol01: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
  selectedGaranteSol02: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
  selectedTitularMS: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:''};
  selectedSolicitud: any={idSolicitud:''};



  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  onClearSelected(){
    this.newSolicitud = {idSolicitud:'',
                          idLineaProducto:'',
                          descLineaProducto:'',
                          idProducto:'',
                          descProducto:'',
                          idTipoProducto:'',
                          descTipoProducto:'',
                          idEstado:'',
                          descEstado:'',
                          idPersonaFyJCliente:'',
                          nroTributarioPersonasFyJCliente:'',
                          descPersonasFyJCliente:'',
                          idActividadEconAfip:'',
                          descActividadF883:'',
                          fechaNacimiento:'',
                          edad:'',
                          sexo:'',
                          idEstadoCivil:'',
                          idNivelEducativo:'',
                          idNivelSE:'',
                          fechaAlta:'',
                          fechaBaja:'',
                          nickNamePromotorAlta:'',
                          descUsuario:'',
                          nickNameUsuarioAlta:'',
                          nickNameUsuarioModif:'',
                          sha256:'',
                          idSolicitudPrestamo:'',
                          idEmprendimiento:'',
                          descEmprendimiento:'',
                          descTipoEmprendimiento: '',
                          descEstadoEmprendimiento: '',
                          descLugarTrabajoEmprendimiento: '',
                          qPersonaFamiliarOcupada: '',
                          qPersonaNoFamiliarOcupada: '',
                          mpTasa:'',
                          mpCuotas:'',
                          mpValorCuota:'',
                          mpMontoSolicitado:'',
                          mpAmortizacion:'',
                          mpGarantia01:'',
                          mpGarantia02:'',
                          mpGaranteSol01IdPFyJ:'',
                          mpGaranteSol01DescPersonasFyJ:'',
                          mpGaranteSol01NroTributario:'',
                          mpGaranteSol02IdPFyJ:'',
                          mpGaranteSol02NroTributario:'',
                          mpGaranteSol02DescPersonasFyJ:'',
                          mpIngresosFamiliares:'',
                          mpGastosFamiliares:'',
                          mpIngresosEmprendimientos:'',
                          mpGastosEmprendimientos:'',
                          mpJefasHogar:'',
                          mpQHijos:'',
                          mpAutomovil:'',
                          mpVivienda:'',
                          mpEntidad:'',
                          mpIdCanalDesembolso:'',
                          mpCBU:'',
                          rieBcraQSit1:'',
                          rieBcraMtoSit1:'',
                          rieBcraQChequeRechUlt12M:'',
                          rieBcraMtoChequeRechUlt12M:'',
                          rieBcraColorChequeRechUlt12M:'',
                          rieBcraQEntSitMay1Ult12M:'',
                          rieBcraMtoEntSitMay1Ult12M:'',
                          rieBcraColorEntSitMay1Ult12M:'',
                          rieAnsesMtoPrestamo:'',
                          rieFundQMPMora:'',
                          rieFundMtoMPMora:'',
                          rieColorFundMtoMPMora:'',
                          rieFundQMPDeuda:'',
                          rieFundMtoMPDeuda:'',
                          rieTotDeudaSistema:'',
                          rieTotDeudaSistemaColor:'',
                          rieRCIMP:'',
                          rieRCIMPColor:'',
                          rieGaranteQMoraFund: '',
                          rieGaranteMtoMoraFund: '',
                          rieGaranteColorMoraFund: '',
                          rieResAprobado:'',
                          rieResAprobadoColor:'',
                          rieResResolucion:'',
                          idPersonaFyJTitular:'',
                          nroTributarioTitular:'',
                          descPersonaFyJTitular:'',
                          idTipoGrupoFam:'',
                          descTipoGrupoFam:'',
                          editable:'',
                          abervEstado:'',
                          mpTasaPunitorios:'',
                          certificado:'',
                          certificadoGar01:'',
                          certificadoGar02:'',
                          idDestino:'',
                          abrevGarantia:''
                        };

    this.oportunidad= { idSolicitud: '',
                        idOportunidad: '',
                        idSitioReferencia: '',
                        idCliente: '',
                        opCuotas: '',
                        opMontoSolicitado: '',
                        opNroTributario: '',
                        opCP: '',
                        idLocalidad: '',
                        opEmail: '',
                        opNroCelular: '',
                        opDomicilio: '',
                        idEstadoOportunidad: '',
                        uuid: '',
                        fechaRegistro: '',
                        ideNroDoc: '',
                        ideDenominacion: '',
                        ideActividad: '',
                        ideQSitUltMes: '',
                        idemtoSit1UltMes: '',
                        ideQSitMay1UltMes: '',
                        ideMtoSitMay1UltMes: '',
                        ideMaxSitUlt24Meses: '',
                        ideMaxMontoUlt24Meses: '',
                        ideQCheques: '',
                        ideMtoCheques: '',
                        ideImpGanancias: '',
                        ideImpIVA: '',
                        ideCategoria: '',
                        ideIntegranteSoc: '',
                        ideEmpleador: '',
                        ideActividadMono: '',
                        idCliOfertaMP: '',
                        cftea: '',
                        destino: '',
                        grupoDestino: '',
                        tna:''};

    this.selectedProducto={idTipoProducto : '',
                    descTipoProducto : '',
                    idProducto : '',
                    descProducto : '',
                    idLineaProducto : '',
                    descLineaProducto : '',
                    descTasa: '',
                    tasa : '',
                    tasaPunitorios:'',
                    cuotas : '',
                    amortizacion : '',
                    emprendedor : '',
                    prima:'',
                    impuestos: '',
                    premio: '',
                    activo : '',
                    visible : ''};

    this.selectedEmprendimiento={ idEmprendimiento: '',
                    descEstadoEmprendimiento: '',
                    descEmprendimiento: '',
                    nroTributario: '',
                    descPersonasFyJ: '',
                    fechaAlta: '',
                    fechaUltModificacion: '',
                    fechaBaja: ''};

    this.selectedCliente={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
    this.selectedGaranteSol01={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
    this.selectedGaranteSol02={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',certificado:''};
    this.selectedTitularMS={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:''};
    
    this.avanzarJson = {
      idSolicitud: '',
      idTipoProducto:'',
      idEstado: '',
      nickNamePromotorAlta: '',
      nickNameUsuarioModif: '',
      sha256: ''
    };
        
  }

  ngOnInit() {
    this.onShowForm='formEditSolicitud'; 
    this.getDataProductos();
    this.onChageValues=false;
  }

  getDataProductos(){
    var respuesta: any;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIProductos)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if ((respuesta['statusCode']=='200') && (respuesta['body']!=[])){   
        this.ltEstructuraProducto=respuesta['body'];
        this.getDataPromotores();
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }


  getDataPromotores(){
    var respuesta: any;  
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIPromotores)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.ltPromotores=respuesta['body']['visibles'];
        this.getData();
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getData(){
    var respuesta: any;
    this.selectedSolicitud.idSolicitud=this.idSolicitud$;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/solicitud',this.selectedSolicitud)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.newSolicitud=respuesta['body'][0];
        this.selectedProducto=this.ltEstructuraProducto.filter(element => (element.idLineaProducto==respuesta['body'][0].idLineaProducto))[0];
        this.selectedCliente.idPersonasFyJ=respuesta['body'][0].idPersonaFyJCliente;
        this.selectedCliente.nroTributario=respuesta['body'][0].nroTributarioPersonasFyJCliente;
        this.selectedCliente.descPersonasFyJ=respuesta['body'][0].descPersonasFyJCliente;
        this.selectedCliente.certificado=respuesta['body'][0].certificado;
        this.selectedEmprendimiento.idEmprendimiento=respuesta['body'][0].idEmprendimiento;
        this.selectedEmprendimiento.descEmprendimiento=respuesta['body'][0].descEmprendimiento;
        
        this.selectedGaranteSol01.idPersonasFyJ=respuesta['body'][0].mpGaranteSol01IdPFyJ;
        this.selectedGaranteSol01.nroTributario=respuesta['body'][0].mpGaranteSol01NroTributario;
        this.selectedGaranteSol01.descPersonasFyJ=respuesta['body'][0].mpGaranteSol01DescPersonasFyJ;
        this.selectedGaranteSol01.certificado=respuesta['body'][0].certificadoGar01;
        this.selectedGaranteSol02.idPersonasFyJ=respuesta['body'][0].mpGaranteSol02IdPFyJ;
        this.selectedGaranteSol02.nroTributario=respuesta['body'][0].mpGaranteSol02NroTributario;
        this.selectedGaranteSol02.descPersonasFyJ=respuesta['body'][0].mpGaranteSol02DescPersonasFyJ;
        this.selectedGaranteSol02.certificado=respuesta['body'][0].certificadoGar02;
        
        this.newSolicitud.nickNameUsuarioModif=this.iUserVO$.user;
        this.onSetEditButtons();
        this.onCalValues();
        this.onChageValues=false;
        this.getDataOPortunidad();
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  getDataOPortunidad(){
    var respuesta: any;
    var soliData:any ={idSolicitud:'',idTenant:''};
    soliData.idSolicitud=this.newSolicitud.idSolicitud;
    soliData.idTenant=this.iUserVO$.idTenant
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPIOportunidad,soliData)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if ((respuesta['statusCode']=='200') && (respuesta['body']!=[])){   
        this.oportunidad=respuesta['body'];
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
    
  }

  /*listenAssignEventProduct(iProducto: any){
    this.selectedProducto=iProducto;
    this.newSolicitud.idLineaProducto=this.selectedProducto.idLineaProducto;
    this.newSolicitud.idTipoProducto=this.selectedProducto.idTipoProducto;
    this.newSolicitud.idProducto=this.selectedProducto.idProducto;
    if(this.selectedProducto.idTipoProducto==1){
      this.newSolicitud.mpTasa=this.selectedProducto.tasa;
      this.newSolicitud.mpTasaPunitorios=this.selectedProducto.tasaPunitorios;
      this.newSolicitud.mpAmortizacion=this.selectedProducto.amortizacion;
      this.onCalValues();
    }
  }*/

  listenAssignEventCliente(iCliente: any){
    this.selectedCliente=iCliente;
    this.newSolicitud.nroTributarioPersonasFyJCliente=this.selectedCliente.nroTributario;
  }


  listenAssignEventGaranteSol01(iGarante: any){
    this.selectedGaranteSol01=iGarante;
    this.newSolicitud.mpGaranteSol01IdPFyJ=this.selectedGaranteSol01.idPersonasFyJ;
  }

  listenAssignEventGaranteSol02(iGarante: any){
    this.selectedGaranteSol02=iGarante;
    this.newSolicitud.mpGaranteSol02IdPFyJ=this.selectedGaranteSol02.idPersonasFyJ;
  }

  listenAssignEventEmprendimiento(iEmprendimiento: any){
    this.selectedEmprendimiento=iEmprendimiento;
    this.newSolicitud.idEmprendimiento=this.selectedEmprendimiento.idEmprendimiento;
  }

  listenAssignEventTitularMS(iTitular: any){
    this.selectedTitularMS=iTitular;
    this.newSolicitud.msTitular=this.selectedTitularMS.idPersonasFyJ;
  }

  onExit(){
    //console.log(JSON.stringify(this.newPersonaFisica));
    this.closeEvent.emit(true);
  }
  
  onSetFormShow(iForm: any){
    if(iForm=='formEmprendimientoSearch'){
      if((this.selectedCliente.idPersonasFyJ!='') && (this.selectedCliente.certificado=='CERTIFICADO')){
        this.onShowForm=iForm;  
      }
      else{
        alert('Seleccione un Cliente Certificado!!');
      }
    }
    else {
      this.onShowForm=iForm;
    }
    this.onChageValues=true;
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='formEditSolicitud';
  }

  onChageGarantia01(iGarantia: String){
    this.newSolicitud.mpGarantia01=iGarantia;
    this.onChageValues=true;
  }

  onChageGarantia02(iGarantia: String){
    this.newSolicitud.mpGarantia02=iGarantia;
    this.onChageValues=true;
  }

  onCodigoError(): string {
    var verifica, v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13, v14, v15, v16, v17: Boolean;
    v1 = (String(this.newSolicitud.idLineaProducto) != '');
    v2 = (String(this.newSolicitud.idPersonaFyJCliente) != '');
    v3 = (String(this.newSolicitud.idEmprendimiento) != '');
    v4 = (String(this.newSolicitud.nickNamePromotorAlta) != '');
    v5 = (String(this.newSolicitud.mpMontoSolicitado) != '' && this.isNumeric(String(this.newSolicitud.mpMontoSolicitado)));
    v6 = (String(this.newSolicitud.mpCuotas).length >= 1);
    v7 = (((String(this.newSolicitud.mpGaranteSol02IdPFyJ).length >= 1) && (String(this.newSolicitud.mpGaranteSol02IdPFyJ).length >= 1) && (this.newSolicitud.abrevGarantia=='GAR_SOLIDARIA_O_RS')) || (this.newSolicitud.abrevGarantia!='GAR_SOLIDARIA_O_RS'));
    v8 = ((String(this.newSolicitud.mpIngresosFamiliares).length >= 1) && this.isNumeric(String(this.newSolicitud.mpIngresosFamiliares)));
    v9 = ((String(this.newSolicitud.mpGastosFamiliares).length >= 1) && this.isNumeric(String(this.newSolicitud.mpGastosFamiliares)));
    v10 = ((String(this.newSolicitud.mpIngresosEmprendimientos).length >= 1) && this.isNumeric(String(this.newSolicitud.mpIngresosEmprendimientos)));
    v11 = ((String(this.newSolicitud.mpGastosEmprendimientos).length >= 1) && this.isNumeric(String(this.newSolicitud.mpGastosEmprendimientos)));

    v12 = (String(this.newSolicitud.mpAutomovil).length >= 1);
    v13 = (String(this.newSolicitud.mpVivienda).length >= 1);
    v14 = (String(this.newSolicitud.mpEntidad).length >= 1);
    v15 = (String(this.newSolicitud.mpIdCanalDesembolso).length >= 1);

    if (this.newSolicitud.mpIdCanalDesembolso == 6) { v16 = this.validarCBU(String(this.newSolicitud.mpCBU)); }
    else { v16 = true; }

    verifica = String(Number(v1)) + String(Number(v2)) + String(Number(v3)) + String(Number(v4)) + String(Number(v5)) + String(Number(v6)) + String(Number(v7)) + String(Number(v8)) + String(Number(v9)) + String(Number(v10)) + String(Number(v11)) + String(Number(v12)) + String(Number(v13)) + String(Number(v14)) + String(Number(v15)) + String(Number(v16));
    return verifica;
  }
 
  onLeyendaError(errorCode: string): string {
    var leyenda: string;
    leyenda = 'ERROR - NO SE PUEDE ACTUALIZAR LA SOLICITUD. \nVERIFIQUE LOS SIGUIENTES CAMPOS: \n';

    if (errorCode.substr(0, 1) == '0') { leyenda = leyenda + 'Producto \n' }
    if (errorCode.substr(1, 1) == '0') { leyenda = leyenda + 'Cliente \n' }
    if (errorCode.substr(2, 1) == '0') { leyenda = leyenda + 'Emprendimiento \n' }
    if (errorCode.substr(3, 1) == '0') { leyenda = leyenda + 'Promotor \n' }
    if (errorCode.substr(4, 1) == '0') { leyenda = leyenda + 'Monto \n' }
    if (errorCode.substr(5, 1) == '0') { leyenda = leyenda + 'Cuotas \n' }
    if (errorCode.substr(6, 1) == '0') { leyenda = leyenda + 'Garantia \n' }
    if (errorCode.substr(7, 1) == '0') { leyenda = leyenda + 'Ingresos Familiares \n' }
    if (errorCode.substr(8, 1) == '0') { leyenda = leyenda + 'Gastos Familiares \n' }
    if (errorCode.substr(9, 1) == '0') { leyenda = leyenda + 'Ingresos Emprendimientos \n' }
    if (errorCode.substr(10, 1) == '0') { leyenda = leyenda + 'Gastos Emprendimientos \n' }
    if (errorCode.substr(11, 1) == '0') { leyenda = leyenda + 'Automovil \n' }
    if (errorCode.substr(12, 1) == '0') { leyenda = leyenda + 'Vivienda \n' }
    if (errorCode.substr(13, 1) == '0') { leyenda = leyenda + 'Banco Principal \n' }
    if (errorCode.substr(14, 1) == '0') { leyenda = leyenda + 'Canal de Pago \n' }
    if (errorCode.substr(15, 1) == '0') { leyenda = leyenda + 'CBU \n' }
    return leyenda;
  }	

  isNumeric(k:string):Boolean{
    var r:Boolean=k.length>0;
    for (var i = 0; i <k.length ; i++) {r=r&&RegExp('[0-9]').test(k[i])}
    return r;
  }

  validarLargoCBU(numero:string):Boolean {
    if (numero.length != 22) { return false; }
    return true;
  }
  
  validarBloque1(bloque1: string):Boolean {
    if (bloque1.length != 8) { return false; }
    var banco = bloque1.substr(0,3);
    var digitoVerificador1 = bloque1[3];
    var sucursal = bloque1.substr(4,3);
    var digitoVerificador2 = bloque1[7];
    var suma = Number(banco[0]) * 7 + Number(banco[1]) * 1 + Number(banco[2]) * 3 + Number(digitoVerificador1) * 9 + Number(sucursal[0]) * 7 + Number(sucursal[1]) * 1 + Number(sucursal[2]) * 3;
    var diferencia = 10 - (suma % 10);
    if (Number(digitoVerificador2)==0){return (Number(diferencia) == Number(10));}
	  else {return (Number(diferencia) == Number(digitoVerificador2));}
  }
  
  validarBloque2(bloque2: string):Boolean {
    if (bloque2.length != 14) { return false; }
    var digitoVerificador = bloque2[13];
    var suma = Number(bloque2[0]) * 3 + Number(bloque2[1]) * 9 + Number(bloque2[2]) * 7 + Number(bloque2[3]) * 1 + Number(bloque2[4]) * 3 + Number(bloque2[5]) * 9 + Number(bloque2[6]) * 7 + Number(bloque2[7]) * 1 + Number(bloque2[8]) * 3 + Number(bloque2[9]) * 9 + Number(bloque2[10]) * 7 + Number(bloque2[11]) * 1 + Number(bloque2[12]) * 3;
    var diferencia = 10 - (suma % 10);
    if (Number(digitoVerificador)==0){return (Number(diferencia) == Number(10));}
	  else{return (Number(diferencia) == Number(digitoVerificador));}
  }
  
  validarCBU(nrocbu: string) {
    return this.validarLargoCBU(nrocbu) && this.validarBloque1(nrocbu.substr(0,8)) && this.validarBloque2(nrocbu.substr(8,14));
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }
  
  onGuardar() {
    var respuesta: any;
    this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI, this.newSolicitud)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          alert('La Solicitud se actualizó exitosamente');
          this.onChageValues = false;
          this.onExit();
        }
        if (respuesta['statusCode'] == '400') {
          alert('ERROR - No se puede actualizar la solicitud');
        }
      })
      .catch((e: any) => {
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  onSetEditButtons() {

    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_001_FRM_001');
    this.btnGuardar=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!==-1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto)!==-1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado)!== -1);
   
    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_002_FRM_001');
    this.btnAvanzar=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto)!== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado)!== -1);
    
    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_003_FRM_001'); 
    this.btnAnular=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto)!== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado)!== -1);
    
    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_004_FRM_001'); 
    this.btnPrecarga=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto)!== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado)!== -1);
    
    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_005_FRM_001'); 
    this.btnFirma=(this.configButtons.roles.indexOf(this.iUserVO$.descRol.toUpperCase())!== -1) && (this.configButtons.tipoProductos.indexOf(this.newSolicitud.idTipoProducto)!== -1) && (this.configButtons.estados.indexOf(this.newSolicitud.idEstado)!== -1);
    
  }

  onAvanzar() {
    var codigoError: String;
    var respuesta: any;
    codigoError = '1111111111111111';

    if (this.onChageValues) {
      alert('Los parametros de la solicitud cambiaron. Por favor guarde los cambios y luego proceda con la acción');
    }
    else {
      if (((this.selectedCliente.certificado == 'CERTIFICADO') && (this.selectedGaranteSol01.certificado == 'CERTIFICADO')
        && (this.selectedGaranteSol02.certificado == 'CERTIFICADO') && (this.newSolicitud.abrevGarantia=='GAR_SOLIDARIA_O_RS'))
        || ((this.selectedCliente.certificado == 'CERTIFICADO') && (this.newSolicitud.abrevGarantia!='GAR_SOLIDARIA_O_RS'))) {
        if (this.onCodigoError() == codigoError) {
          this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_002_FRM_001');
          this.onSetNewState(this.configButtons.nextState);
        }
        else {
          alert(this.onLeyendaError(this.onCodigoError()));
        }
      }
      else {
        alert('El cliente y/o los garantes en caso de existir, deben estar certificados');
      }
    }
  }

  onAnular(){
    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_003_FRM_001');
    this.onSetNewState(this.configButtons.nextState);
}

  onPrecarga() {
    if (this.onChageValues) {
      alert('Los parametros de la solicitud cambiaron. Por favor guarde los cambios y luego proceda con la acción');
    }
    else {
      this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_004_FRM_001');
      this.onSetNewState(this.configButtons.nextState);
    }
  }

  onFirmar() {
    this.configButtons=this.confSMEnabledButtons.find(element => element.id==='BTN_005_FRM_001');
    this.onSetNewState(this.configButtons.nextState);
  }

  onSetNewState(iNewState:number) {
    var respuesta: any;
    this.avanzarJson.idSolicitud = this.newSolicitud.idSolicitud;
    this.avanzarJson.idEstado = iNewState;
    this.avanzarJson.idTipoProducto = this.newSolicitud.idTipoProducto;
    this.avanzarJson.nickNamePromotorAlta = this.newSolicitud.nickNamePromotorAlta;
    this.avanzarJson.nickNameUsuarioModif = this.iUserVO$.user;
    this.avanzarJson.sha256 = this.newSolicitud.sha256;

    this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI + '/estado', this.avanzarJson)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          alert('La operación se realizó exitosamente');
          this.onExit();
        }
        if (respuesta['statusCode'] == '400') {
          alert('ERROR - No se puede efectuar la operación');
        }
      })
      .catch((e: any) => {
        // error in e.message
        //alert('Error: ' + e.message);
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  onCalValues(){
    var tem:number;
    tem=(Number(this.newSolicitud.mpTasa)/100.0)/12.0;
    //this.newSolicitud.rieValorCuota=Math.round(Number(this.newSolicitud.mpMontoSolicitado)*((tem)/(1-Math.pow((1+Number(tem)),-1*Number(this.newSolicitud.mpCuotas)))));   
    this.onChageValues=true;  
    this.newSolicitud.mpValorCuota=Math.round(Number(this.newSolicitud.mpMontoSolicitado)*((Math.pow((1+tem),Number(this.newSolicitud.mpCuotas))*tem)/(Math.pow((1+tem),Number(this.newSolicitud.mpCuotas))-1)));
  
    if (!isFinite(this.newSolicitud.mpValorCuota) || isNaN(this.newSolicitud.mpValorCuota)){this.newSolicitud.mpValorCuota=0;}
  }

  onChangeEdit(){
    this.onChageValues=true;
  }

  setCertificado(iState:String):String{
    var respuesta: String;
    if(iState=='CERTIFICADO'){respuesta='label label-success pull-rigth';}
    if(iState=='NO CERTIFICADO'){respuesta='label label-danger pull-rigth';}
    return respuesta;
  }


  setState(iState:String):String{
    var respuesta: String;
    if(iState=='PRECARGA'){respuesta='label label-default pull-right';}
    if(iState=='APROBADA'){respuesta='label label-success pull-right';}
    if(iState=='BAJA'){respuesta='label label-info pull-right';}
    if(iState=='ANULADA'){respuesta='label label-info pull-right';}
    if(iState=='VIGENTE'){respuesta='label label-primary pull-right';}
    if(iState=='CANCELADA'){respuesta='label label-primary pull-right';}
    if(iState=='MORA'){respuesta='label label-warning pull-right';}
    if(iState=='PENDIENTE'){respuesta='label label-warning pull-right';}
    if(iState=='RECHAZADA'){respuesta='label label-danger pull-right';}
    if(iState=='DESEMBOLSADA'){respuesta='label label-success pull-right';}
    if(iState=='INCOBRABLE'){respuesta='label label-danger pull-right';}
    if(iState=='RECUPERO'){respuesta='label label-warning pull-right';}
    if(iState=='OPORTUNIDAD'){respuesta='label label-info pull-right';}
    if(iState=='FUERA ZONA'){respuesta='label label-danger pull-right';}
    return respuesta;
  }

}


/*onChangeGrupoFam(){
    this.onChageValues=true;
    if (this.newSolicitud.idTipoGrupoFam==1){
      this.selectedTitularMS=this.selectedCliente;
      this.newSolicitud.idPersonaFyJTitular=this.selectedTitularMS.idPersonasFyJ;
    }
    else{
      if((this.newSolicitud.idPersonaFyJTitular==this.selectedCliente.idPersonasFyJ)){
        this.selectedTitularMS={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:''};
        this.newSolicitud.idPersonaFyJTitular='';
      }
    }
  }*/