import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import {formatDate} from '@angular/common';
import {formatNumber} from '@angular/common';

@Component({
  selector: 'app-desembolsar-prestamo',
  templateUrl: './desembolsar-prestamo.component.html'
})
export class DesembolsarPrestamoComponent implements OnInit {

  @Input() iUserVO$: User;
  @Input() selectedSolicitud: any;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string = 'https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes/desembolso';

  public cuotas: any;
  public btnDesembolsar: boolean = true;

  public operacion: any = {
    idSolicitudPrestamo: '',
    idSolicitud: '',
    nroOperacionTransf: '',
    fechaDesembolso: '',
    totalDesembolso: '',
    IdCanalDesembolso: '',
    nickNameUsuarioModif:''  
  }

  public liquidacion: any = {
    idProdPrestamoLiq: '',
    idSolicitudPrestamo: '',
    idSolicitud: '',
    tasaLiq: '',
    cuotasLiq: '',
    montoLiq: '',
    totalDesembolso: '',
    fechaLiquidacion: '',
    CBU: '',
    IdCanalDesembolso: '',
    descCanalDesembolso: ''
  };

  constructor(private getDataApiService: GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    var fecha: Date;
    this.btnDesembolsar = true;
    fecha = new Date();
    this.operacion.fechaDesembolso = formatDate(fecha, 'yyyy-MM-dd', 'en');
    this.onGetData();
  }


  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }


  onExit() {
    this.closeEvent.emit(true);
  }

  onGetData() {
    var respuesta: any;
    this.selectedSolicitud.nickNameUsuarioModif = this.iUserVO$.user;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI, this.selectedSolicitud)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          this.liquidacion = respuesta['body'];
          this.operacion.totalDesembolso = '$ ' + formatNumber(Number(this.liquidacion.totalDesembolso),'en-US', '1.2-2');
        }
        if (respuesta['statusCode'] == '400') {
          alert('ERROR - No se puede obtener los datos del producto');
        }
      })
      .catch((e: any) => {
        // error in e.message
        //alert('Error: ' + e.message);
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  onDesembolsar() {
    var respuesta: any;
    if (String(this.operacion.nroOperacionTransf).length>4) {
      this.operacion.idSolicitudPrestamo = this.liquidacion.idSolicitudPrestamo;
      this.operacion.idSolicitud = this.liquidacion.idSolicitud;
      this.operacion.IdCanalDesembolso = this.liquidacion.IdCanalDesembolso;
      this.operacion.nickNameUsuarioModif = this.iUserVO$.user;
      this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI, this.operacion)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta = JSON.parse(data);
          if (respuesta['statusCode'] == '200') {
            alert('El numero de operación se registró exitosamente');
            this.btnDesembolsar = false;
          }
          if (respuesta['statusCode'] == '400') {
            alert('ERROR - No se puede registrar el numero de operación');
          }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    }
    else{
      alert('Complete el número de operación');
    }
  }

}
