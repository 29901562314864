import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-analisis-crediticio',
  templateUrl: './analisis-crediticio.component.html',
  styles: []
})
export class AnalisisCrediticioComponent implements OnInit {

  
  @Input() iUserVO$: User;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  public criterio$: string='';
  public promotor: any={nickNamePromotorAlta:''};

  public cleanSolicitud: any={idSolicitud: '',
                                idLineaProducto: '',
                                descLineaProducto: '',
                                idEstado: '',
                                descEstado: '',
                                abrevEstado: '',
                                nroTributarioCliente: '',
                                descCliente: '',
                                fechaAlta: '',
                                fechaBaja: '',
                                descUsuario: '',
                                idProducto: '',
                                descProducto: '',
                                fechaPrimeraCuota:''
                              };

  public selectedSolicitud:any = this.cleanSolicitud;
  
  public urlAPI: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';                             
  public onShowForm: string=''; 
  public solicitudes = [];
  public solicitudesFiltro = []; 

   
  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { } 

  ngOnInit() {
    this.onShowForm='formAnalisisCrediticio';
    this.selectedSolicitud=this.cleanSolicitud;
    this.criterio$='';
    this.solicitudes = [];
    this.solicitudesFiltro = [];
    this.getData();
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  
  getData(){
    var respuesta: any;
    this.promotor.nickNamePromotorAlta=this.iUserVO$.user;
    this.getDataApiService.getDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/riesgo')
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if (respuesta['statusCode']=='200'){   
        this.solicitudes=respuesta['body'];
        this.solicitudesFiltro=this.solicitudes;
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onSearchCriterio(){
    this.selectedSolicitud=this.cleanSolicitud;
    this.solicitudesFiltro =[];
    this.solicitudesFiltro=this.solicitudes.filter(element => element.abrevEstado.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || element.descCliente.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1 || String(element.nroTributarioCliente).indexOf(this.criterio$,0)>-1 || String(element.idSolicitud).indexOf(this.criterio$,0)>-1 || element.descLineaProducto.toLowerCase().indexOf(this.criterio$.toLowerCase(),0)>-1);
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  onSelect(iSolicitud:any){
    this.selectedSolicitud=iSolicitud;
  }

  onAssign(){
    if (this.selectedSolicitud.idSolicitud!='') {
      this.assignEvent.emit(this.selectedSolicitud);
      this.closeEvent.emit(true);
    } 
    else{
      alert('Debe seleccionar una solicitud');
    } 
  }

  setState(iState:String):String{
    var respuesta: String;
    if(iState=='PRECARGA'){respuesta='label label-default pull-right';}
    if(iState=='APROBADA'){respuesta='label label-success pull-right';}
    if(iState=='BAJA'){respuesta='label label-info pull-right';}
    if(iState=='ANULADA'){respuesta='label label-info pull-right';}
    if(iState=='VIGENTE'){respuesta='label label-primary pull-right';}
    if(iState=='CANCELADA'){respuesta='label label-primary pull-right';}
    if(iState=='MORA'){respuesta='label label-warning pull-right';}
    if(iState=='PENDIENTE'){respuesta='label label-warning pull-right';}
    if(iState=='RECHAZADA'){respuesta='label label-danger pull-right';}
    if(iState=='DESEMBOLSADA'){respuesta='label label-success pull-right';}
    if(iState=='INCOBRABLE'){respuesta='label label-danger pull-right';}
    if(iState=='RECUPERO'){respuesta='label label-warning pull-right';}
    return respuesta;
  }


  onEditSol(){
    if (this.selectedSolicitud.idSolicitud!='') {
      this.onShowForm='formAnalisisCrediticioEdit';
    } 
    else{
      alert('Debe seleccionar una solicitud');
    } 
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.ngOnInit();
  }

  onRefresh(){
    this.ngOnInit();
  }

  onLiquidarMP(){
    var fecha: Date; var fechaStr: string;
    if ((this.selectedSolicitud.idSolicitud!='') && (this.selectedSolicitud.idEstado==17)) {
      this.selectedSolicitud.fechaPrimeraCuota=this.onNextFirsFee();
      this.onShowForm='formLiquidarPrestamo';
    } 
    else{
      alert('Debe seleccionar una solicitud aprobada');
    } 
  }

  onDesembolsarMP(){
    var fecha: Date; var fechaStr: string;
    if ((this.selectedSolicitud.idSolicitud!='') && (this.selectedSolicitud.idEstado==28)) {
      this.onShowForm='formDesembolsarPrestamo';
    } 
    else{
      alert('Debe seleccionar una solicitud liquidada');
    } 
  }

  onNextFirsFee(){
    var fecha: Date; var fechaStr,fechaStr2: string;
    fecha=new Date();
    if (fecha.getDate()<=15){
      fecha.setDate(fecha.getDate()+30);
    }
    else {
      fecha.setDate(fecha.getDate()+60);
    }
    fechaStr=formatDate(fecha, 'yyyy-MM-dd', 'en');
    fechaStr2=fechaStr.substr(0,4) + '-' + fechaStr.substr(5,2) + '-10';
    return formatDate(fechaStr2, 'yyyy-MM-dd', 'en');
  }

}
