import { Component, OnInit,Output,Input,EventEmitter,Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import {User} from '../../../models/user';
import  *  as  jsLocalidades  from  '../../../../assets/catalogo/jsLocalidades.json';
import  *  as  jsEstadosEmprendimientos from  '../../../../assets/catalogo/jsEstadosEmprendimientos.json';
import  *  as  jsTiposEmprendimientos from  '../../../../assets/catalogo/jsTiposEmprendimientos.json';
import  *  as  jsLugaresTrabajoEmprendimientos from  '../../../../assets/catalogo/jsLugaresTrabajoEmprendimientos.json';
import { globals } from 'src/app/globals';

@Component({
  selector: 'app-emprendimientos-new',
  templateUrl: './emprendimientos-new.component.html'
})
export class EmprendimientosNewComponent implements OnInit {

  @Input() iUserVO$: User;
  @Input() idPersonaFyJEmprendedor$: String;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  
  urlAPI: string='https://3clcee2ns9.execute-api.us-east-2.amazonaws.com/beta/dataemprendimientos';

  onShowForm: string='';
  public emprendedores = [];
  statusForm:string='PRECARGA';
  labelDisabled:string='true';

  ltLocalidades: any = (jsLocalidades as any).default;
  ltEstadosEmprendimientos: any = (jsEstadosEmprendimientos as any).default;
  ltLugaresTrabajosEmprendimientos: any = (jsLugaresTrabajoEmprendimientos as any).default;
  ltTiposEmprendimientos: any = (jsTiposEmprendimientos as any).default;

  newEmprendimiento: any= { descEmprendimiento : '',
                            idPersonasFyJ : '',
                            idEstadoEmprendimiento : '',
                            idTipoEmprendimiento : '',
                            idLugarTrabajoEmprendimiento : '',
                            idActividadEconAfip : '',
                            qPersonaFamiliarOcupada : '',
                            qPersonaNoFamiliarOcupada : '',
                            email : '',
                            nroTelefono : '',
                            nroInterno : '',
                            nroCelular : '',
                            idLocalidad : '',
                            calle : '',
                            numero : '',
                            piso : '',
                            depto: '',
                            manzana: '',
                            casa: '',
                            barrio: ''};

  selectedActividadEconomica: any={ idActividadEconAfip : '',
                            codActividadF883 : '',
                            descActividadF883 : '',
                            desclActividadF883 : ''};


  selectedEmprendedor: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',idEmprendimiento:'',estado:''};
  selectedAddEmprendedor: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',idEmprendimiento:''};
  selectedEmprendimiento: any={idEmprendimiento:''};
  selectedPerson: any={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',promotor:'',certificado:''};

  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.onShowForm='formNuevoEmprendimiento';
    this.onClear();
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onExit(){
    //console.log(JSON.stringify(this.newPersonaFisica));
    this.closeEvent.emit(true);
  }

  onClear(){
    this.newEmprendimiento={ descEmprendimiento : '',
                              idPersonasFyJ : '',
                              idEstadoEmprendimiento : '',
                              idTipoEmprendimiento : '',
                              idLugarTrabajoEmprendimiento : '',
                              idActividadEconAfip : '',
                              qPersonaFamiliarOcupada : '',
                              qPersonaNoFamiliarOcupada : '',
                              email : '',
                              nroTelefono : '',
                              nroInterno : '',
                              nroCelular : '',
                              idLocalidad : '',
                              calle : '',
                              numero : '',
                              piso : '',
                              depto: '',
                              manzana: '',
                              casa: '',
                              barrio: ''};

    this.selectedActividadEconomica={ idActividadEconAfip : '',
                                      codActividadF883 : '',
                                      descActividadF883 : '',
                                      desclActividadF883 : ''};

    this.selectedEmprendedor= {idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',idEmprendimiento:'',estado:''};
    this.selectedAddEmprendedor= {idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',idEmprendimiento:''};
    this.emprendedores = [];
    this.statusForm='PRECARGA';
    this.selectedEmprendimiento={idEmprendimiento:''};
    this.selectedPerson={idPersonasFyJ:'',nroTributario:'',descPersonasFyJ:'',descTipoPersona:'',promotor:'',certificado:''};
  }

  onSetFormShow(iForm: any){
    this.onShowForm=iForm;
  }


  onAceptar(){
    if (this.onCodigoError()=='111111111'){
      var respuesta: any;
      this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI,this.newEmprendimiento)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta=JSON.parse(data);
        if (respuesta['statusCode']=='200'){
          alert('El emprendimiento se registró exitosamente. Cargar los emprendedores asociados al emprendimiento');
            this.selectedEmprendimiento.idEmprendimiento=respuesta['body'];
            this.statusForm='CARGA_EMPRENDEDORES';   
          if (String(this.idPersonaFyJEmprendedor$).length>0){
            this.selectedPerson.idPersonasFyJ=this.idPersonaFyJEmprendedor$;
            this.listenAssignEventEmprendedor(this.selectedPerson);
          }
        }
        if ((respuesta['statusCode']=='400') && (respuesta['body']=='1062')){   
          alert('ERROR - El emprendimiento ya se encuentra registrado');
        }
        if ((respuesta['statusCode']=='400') && (respuesta['body']!='1062')){   
          alert('ERROR - No se puede registrar el emprendimiento, revise los datos OBLIGATORIOS');
        }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
      
    }
    else{
      alert(this.onLeyendaError(this.onCodigoError()));
    }
  }

  onCodigoError():string{
    var verifica,v1,v2,v3,v4,v5,v6,v7,v8,v9,v10:Boolean;
    v1=(String(this.newEmprendimiento.descEmprendimiento).length >=3);
    //v2=(String(this.newEmprendimiento.idPersonasFyJ).length>0);
    v3=(String(this.newEmprendimiento.idEstadoEmprendimiento).length >=1);
    v4=(String(this.newEmprendimiento.idTipoEmprendimiento).length >=1);
    v5=(String(this.newEmprendimiento.idLugarTrabajoEmprendimiento).length >=1);
    v6=(String(this.newEmprendimiento.qPersonaFamiliarOcupada).length >=1 && this.isNumeric(String(this.newEmprendimiento.qPersonaFamiliarOcupada)));
    v7=(String(this.newEmprendimiento.qPersonaNoFamiliarOcupada).length >=1 && this.isNumeric(String(this.newEmprendimiento.qPersonaNoFamiliarOcupada)));
    v8=(String(this.newEmprendimiento.idActividadEconAfip).length >=1);
    v9=(String(this.newEmprendimiento.nroCelular).length >=9  && this.isNumeric(String(this.newEmprendimiento.nroCelular))); 
    v10=(String(this.newEmprendimiento.idLocalidad).length >=1);
   
    verifica=String(Number(v1)) +  String(Number(v3)) + String(Number(v4)) + String(Number(v5)) + String(Number(v6)) + String(Number(v7)) + String(Number(v8)) + String(Number(v9)) + String(Number(v10));
    return verifica;
 }
 
 onLeyendaError(errorCode: string):string{
    var leyenda:string;
    leyenda='ERROR - NO SE PUEDE DAR DE ALTA LA PERSONA. \nVERIFIQUE LOS SIGUIENTES CAMPOS: \n';
    if (errorCode.substr(0,1)=='0'){leyenda=leyenda + 'Descripcion del Emprendimiento \n' }
    //if (errorCode.substr(1,1)=='0'){leyenda=leyenda + 'Titular del Emprendimiento \n' }
    if (errorCode.substr(1,1)=='0'){leyenda=leyenda + 'Estado del Emprendimiento \n' }
    if (errorCode.substr(2,1)=='0'){leyenda=leyenda + 'Tipo de Emprendimiento \n' }
    if (errorCode.substr(3,1)=='0'){leyenda=leyenda + 'Lugar de Trabajo del Emprendimiento \n' }
    if (errorCode.substr(4,1)=='0'){leyenda=leyenda + 'Persona Familiar Ocupada \n' }
    if (errorCode.substr(5,1)=='0'){leyenda=leyenda + 'Persona Familiar No Ocupada \n' }
    if (errorCode.substr(6,1)=='0'){leyenda=leyenda + 'Actividad Económica \n' }
    if (errorCode.substr(7,1)=='0'){leyenda=leyenda + 'Nro. Celular \n' }
    if (errorCode.substr(8,1)=='0'){leyenda=leyenda + 'Localidad \n' }
    return leyenda;
  }	

  isNumeric(k:string):Boolean{
    var r:Boolean=k.length>0;
    for (var i = 0; i <k.length ; i++) {r=r&&RegExp('[0-9]').test(k[i])}
    return r;
  }

  listenAssignEventAfip(iActividadEconomica: any){
    this.selectedActividadEconomica=iActividadEconomica;
    this.newEmprendimiento.idActividadEconAfip=String(this.selectedActividadEconomica.idActividadEconAfip);
  }

  listenAssignEventEmprendedor(iEmprendedor: any){
    this.selectedAddEmprendedor=iEmprendedor;
    this.selectedAddEmprendedor.idEmprendimiento=this.selectedEmprendimiento.idEmprendimiento;
    var respuesta: any;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/emprendedor',this.selectedAddEmprendedor)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta=JSON.parse(data);
        if (respuesta['statusCode']=='200'){   
          alert('El emprendedor se registro correctamente');
          this.getEmprendedores();
        }
        if ((respuesta['statusCode']=='400') && (respuesta['body']=='1062')){   
          alert('ERROR - El emprendedor ya se encuentra registrado');
        }
        if ((respuesta['statusCode']=='400') && (respuesta['body']!='1062')){   
          alert('ERROR - No se puede registrar el emprendedor.');
        }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    
  }

  listenCloseEvent(iMenuItemSelected: any){
    this.onShowForm='formNuevoEmprendimiento';
  }

  getEmprendedores(){
    var respuesta: any;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/emprendedor/emprendimiento',this.selectedEmprendimiento)
    .then((response: any) => response.text())
    .then((data: any) => {
      respuesta=JSON.parse(data);
      if ((respuesta['statusCode']=='200') && (respuesta['body']!=[])){   
        this.emprendedores=respuesta['body'];
        console.log(this.emprendedores);
      }
    })
    .catch((e: any) => {
      // error in e.message
      alert('Sesión Finalizada. Ingrese nuevamente');
      this.closeSession();
    });
  }

  onSelect(iEmprendedor:any){
    this.selectedEmprendedor=iEmprendedor;
    this.selectedEmprendedor.idEmprendimiento=this.selectedEmprendimiento.idEmprendimiento;
    this.selectedEmprendedor.estado=iEmprendedor.estado;
  }

  onDown(){
    var respuesta: any;
    if (this.selectedEmprendedor.estado=='ACTIVO'){
        this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken,this.urlAPI+'/emprendedor',this.selectedEmprendedor)
          .then((response: any) => response.text())
          .then((data: any) => {
            respuesta=JSON.parse(data);
            if (respuesta['statusCode']=='200'){   
              alert('El emprendedor dio de baja correctamente');
              this.getEmprendedores();
            }
            })
            .catch((e: any) => {
              // error in e.message
              //alert('Error: ' + e.message);
              alert('Sesión Finalizada. Ingrese nuevamente');
              this.closeSession();
            });
      }
      else{
        alert('El emprendedor ya se encontraba dado de baja');
        
      }
  }

}
