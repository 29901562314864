import { Component, OnInit,EventEmitter, Output, Input,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT } from '@angular/common';
import { globals } from 'src/app/globals';
import *  as  jsSMEnabledButtons from '../../../../assets/catalogo/jsStateMachineEnabledButtonsWorkflow.json';


@Component({
  selector: 'app-liquidar-prestamo',
  templateUrl: './liquidar-prestamo.component.html'
})
export class LiquidarPrestamoComponent implements OnInit {

  @Input() iUserVO$: User;
  @Input() selectedSolicitud: any;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlAPI: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes';

  public cuotas:any;
  public btnLiquidar:boolean=true;
  public btnRetroceder: boolean = true;

  confSMEnabledButtons: any = (jsSMEnabledButtons as any).default;

  configButtons: any = {
    id: '',
    form: '',
    boton: '',
    tipoProductos: '',
    estados: '',
    roles: ''
  };

  accionJson: any = {
    idSolicitud: '',
    idTipoProducto: '',
    idEstado: '',
    nickNamePromotorAlta: '',
    nickNameUsuarioModif: '',
    sha256: ''
  };

  public liquidacion: any = { idProdPrestamoLiq: '',
                              idSolicitudPrestamo: '',
                              tasaLiq: '',
                              cuotasLiq: '',
                              montoLiq: '',
                              costoSaldoDeudor: '',
                              costoAmparo: '',
                              prima: '',
                              gastoAdministrativo: '',
                              tasaSuperIntendencia: '',
                              subTotalSeguros: '',
                              administracionFundacion: '',
                              totalSeguroCuota: '',
                              totalSegurosLiquidacion: '',
                              socioAdherente: '',
                              retencionDesembolso: '',
                              totalDesembolso: '',
                              tem: '',
                              valorCuotaLiq: '',
                              fechaDesembolso: '',
                              idCanalDesembolso: '',
                              descCanalDesembolso: '',
                              cbu: '',
                              idEstado: '',
                              cuotasLiquidadas: '',
                              fechaLiquidacion:''};

  constructor(private getDataApiService:GetDataAPIService,@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {  
    this.btnLiquidar=true;
  }

  setState(iState:String):String{
    var respuesta: String;
    if(iState=='N'){respuesta='label label-danger pull-right';}
    if(iState=='S'){respuesta='label label-success pull-right';}
    return respuesta;
  }

  setStateCuota(iState:String):String{
    var respuesta: String;
    if(iState=='TIEMPO Y FORMA'){respuesta='label label-success pull-right';}
    if(iState=='ADEUDADA'){respuesta='label label-warning pull-right';}
    if(iState=='ATRASADA'){respuesta='label label-danger pull-right';}
    if(iState=='ANTICIPADA'){respuesta='label label-success pull-right';}
    if(iState=='REFINANCIADA'){respuesta='label label-info pull-right';}
    if(iState=='REPROGRAMADA'){respuesta='label label-primary pull-right';}
    if(iState=='FONDO GARANTIA'){respuesta='label label-default pull-right';}
    if(iState=='FUERA TERMINO'){respuesta='label label-warning pull-right';}
    return respuesta;
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

  onLiquidar() {
    var dias: number; var primeraCuota, hoy: Date;
    var respuesta: any;

    primeraCuota = new Date(this.selectedSolicitud.fechaPrimeraCuota);
    hoy = new Date();
    dias = Math.round((primeraCuota.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24));

    if (dias >= 20) {
      this.selectedSolicitud.nickNameUsuarioModif = this.iUserVO$.user;
      this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI + '/riesgo', this.selectedSolicitud)
        .then((response: any) => response.text())
        .then((data: any) => {
          respuesta = JSON.parse(data);
          if (respuesta['statusCode'] == '200') {
            alert('La Solicitud de microcrédito se liquidó exitosamente');
            this.liquidacion = respuesta['body'];
            this.btnLiquidar = false;
            this.btnRetroceder=false;
          }
          if (respuesta['statusCode'] == '400') {
            alert('ERROR - No se puede liquidar la solicitud de microcrédito');
          }
        })
        .catch((e: any) => {
          // error in e.message
          //alert('Error: ' + e.message);
          alert('Sesión Finalizada. Ingrese nuevamente');
          this.closeSession();
        });
    }
    else{
      alert('La fecha de vencimento de la primera cuota tiene que ser mayor que 20 dias a partir de hoy.');
    }

  }

  onExit(){
    this.closeEvent.emit(true);
  }


  onRetroceder() {
    this.configButtons = this.confSMEnabledButtons.find(element => element.id === 'BTN_001_FRM_003');
    this.onSetNewState(this.configButtons.nextState);
  }

  onSetNewState(iNewState: number) {
    var respuesta: any;
    this.accionJson.idSolicitud = this.selectedSolicitud.idSolicitud;
    this.accionJson.idEstado = iNewState;
    this.accionJson.nickNamePromotorAlta = this.iUserVO$.user;
    this.accionJson.nickNameUsuarioModif = this.iUserVO$.user;
    
    this.getDataApiService.putDataAPIFetch(this.iUserVO$.jwtToken, this.urlAPI + '/estado', this.accionJson)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          alert('La operación se realizó exitosamente');
          this.onExit();
        }
        if (respuesta['statusCode'] == '400') {
          alert('ERROR - No se puede efectuar la operación');
        }
      })
      .catch((e: any) => {
        // error in e.message
        //alert('Error: ' + e.message);
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

}
