import { Component, OnInit,  EventEmitter, Output,  } from '@angular/core';
import {User} from '../../../models/user';
import {LoginUser} from '../../../models/login-user';
import {AuthorizationService} from '../../../services/authorization/authorization.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles:["../../../../assets/dist/css/stylelog.css"]
})
export class LoginComponent implements OnInit {

  userVO: User = new User();
  loginUserVO: LoginUser = new LoginUser();
  eyeOpen: boolean = false;
  eyeType: string = "glyphicon glyphicon-eye-close form-control-feedback";
 

  @Output() eventLogged: EventEmitter<User> = new EventEmitter<User>();

  constructor(private auth: AuthorizationService) { }
  

  ngOnInit() {
  }

  public getUser():string
  {
    return this.loginUserVO.user;
  }

  getLoginUser() {
    
    this.auth.signIn(this.loginUserVO.user, this.loginUserVO.pwd).subscribe((data) => {
        this.userVO.user=data.idToken.payload.nickname;
        this.userVO.apellidoNombre=data.idToken.payload.name;
        this.userVO.descRol=data.idToken.payload.profile;
        this.userVO.isLogged=true;
        this.userVO.jwtToken=data.idToken.jwtToken;
        this.userVO.idTenant=data.idToken.payload.zoneinfo;
        this.userVO.institucion=data.idToken.payload.locale;
        this.userVO.picture=data.idToken.payload.picture;
        this.eventLogged.emit(this.userVO);
        
    }, (err)=> {
      this.userVO.isLogged=false;
      alert('Usuario o Password incorrecta.');
    });   
  }

  changeEye(){
    if (this.loginUserVO.pwd.length>0){
      this.eyeOpen=!this.eyeOpen;
      if (this.eyeOpen) {
        this.eyeType="glyphicon glyphicon-eye-open form-control-feedback";
      }
      else {
        this.eyeType="glyphicon glyphicon-eye-close form-control-feedback";
      }
    }
  }

  getEyeType(){
    return this.eyeType;
  }


}
