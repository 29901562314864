import { Component, OnInit,Output, Input, EventEmitter,Inject } from '@angular/core';
import {User} from '../../../models/user';
import {GetDataAPIService} from '../../../services/getDataAPI/get-data-api.service';
import { DOCUMENT,formatDate} from '@angular/common';
import { globals } from 'src/app/globals';


@Component({
  selector: 'app-split', 
  templateUrl: './split.component.html'
})
export class SplitComponent implements OnInit {

  @Input() iUserVO$: User;
  @Input() iSelectedSolicitud: any;
  @Input() iLiquidacion: any;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  urlSplit: string='https://pglm8tsmef.execute-api.us-east-2.amazonaws.com/beta/datasolicitudes/split';

  ltSplit:any=[{id:1,descripcion:'1'},{id:2,descripcion:'2'},{id:3,descripcion:'3'},{id:4,descripcion:'4'},{id:5,descripcion:'5'},{id:6,descripcion:'6'},{id:7,descripcion:'7'},{id:8,descripcion:'8'},{id:9,descripcion:'9'},{id:10,descripcion:'10'}];

  split: any={idTenant:'',nroSplit:'1',idSolicitud:''};

  constructor(private getDataApiService:GetDataAPIService, @Inject(DOCUMENT) private document: Document) { }


  ngOnInit() {
  }

  onAceptar() {
    var respuesta: any;
    this.split.idTenant=this.iUserVO$.idTenant;
    this.split.idSolicitud=this.iSelectedSolicitud.idSolicitud;
    this.getDataApiService.postDataAPIFetch(this.iUserVO$.jwtToken, this.urlSplit, this.split)
      .then((response: any) => response.text())
      .then((data: any) => {
        respuesta = JSON.parse(data);
        if (respuesta['statusCode'] == '200') {
          alert('La solicitud se dividió exitosamente');
          this.onExit();
        }
    
        if ((respuesta['statusCode'] == '400') && (respuesta['body'] != '1062')) {
          alert('ERROR - No se puede dividir la solicitud');
        }
      })
      .catch((e: any) => {
        // error in e.message
        //alert('Error: ' + e.message);
        alert('Sesión Finalizada. Ingrese nuevamente');
        this.closeSession();
      });
  }

  onExit(){
    this.closeEvent.emit(true);
  }

  closeSession(): void {
    this.document.location.href = globals.urlServer;
  }

}
